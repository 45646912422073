import * as React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  Grid
} from "@mui/material";
import EditorComp from "../../EditorComp";
import googleMyBusinesslogo from "../../../../assets/images/google-my-business.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Picker from "emoji-picker-react";
import "../iconsBox.css";
import { useSelector } from "react-redux";

function GoogleBusinessBox({ register }) {
  const { allSocialAccountData } = useSelector((state) => state.user);

  const [google_radioValue, setGoogleRadioValue] = React.useState("New");
  const [buttonType, setButtonType] = React.useState("book");
  const [checked, setChecked] = React.useState(false);
  const [inputStr, setInputStr] = React.useState([]);
  const [showPicker, setShowPicker] = React.useState(false);
  const [inputText, setInputText] = React.useState("");

  const handleEmojiSelect = (emoji) => {
    setInputStr([...inputStr, emoji]);
    setShowPicker(false);

  };

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeInputGoogle = (e) => {
    setGoogleRadioValue(e.target.value);
  };
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const todayEndOfTheDay = today.endOf("day");

  const handleChangeButtonType = (event) => {
    setButtonType(event.target.value);
  };

  const handleDeleteEmoji = () => {
    setInputStr(inputStr.slice(0, -1));
  };
  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputText) {
      setInputStr([...inputStr, { text: inputText }]);
      setInputText("");
    }
    else if (event.key === 'Backspace' && !inputText && inputStr.length > 0) {
    handleDeleteEmoji(inputStr.length - 1);
  }
};


  return (
    <Grid className="events-box">
      <Box display="flex">
        <img
          className="sub-title-image-card"
          alt=""
          src={googleMyBusinesslogo}
        />
        <Typography variant="header2" className="sub-title-card">
        {allSocialAccountData?.google?.pageName}
        </Typography>
      </Box>
      <Divider orientation="middle" flexItem />

      <Box align="left" sx={{ ml: 3 }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            defaultValue="New"
            onChange={handleChangeInputGoogle}
            value={google_radioValue}
            
          >
            <FormControlLabel
              value="New"
              control={<Radio />}
              label="What's new"
              sx={{fontSize:"14px"}}
            />
            <FormControlLabel value="Event" control={<Radio />} label="Event" />
            <FormControlLabel value="Offer" control={<Radio />} label="Offer" />
          </RadioGroup>
        </FormControl>
      </Box>
      <EditorComp textAreaName={"twitter"} register={register} />
      <Box>
        {google_radioValue === "New" && (
          <Box display="flex" sx={{ ml: 4 }}>
            <Typography sx={{ mt: 2, mr: 2 }} variant="h6">
              Button Type (optional)
            </Typography>
            <Select
              labelId="label"
              id="selectButtonType"
              value={buttonType}
              align="left"
              onChange={handleChangeButtonType}

            >
              <MenuItem value="book" >Book</MenuItem>
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="Shop">Shop</MenuItem>
              <MenuItem value="learnMore">Learn More</MenuItem>
              <MenuItem value="signup">Sign-up</MenuItem>
              <MenuItem value="callNow">Call Now</MenuItem>
            </Select>
          </Box>
        )}
      </Box>

      {google_radioValue === "Event" && (
        <Box align="left">
          <label className="first-comment">First comment</label>
          <br />
          <Box  sx={{ ml: 3.5, mt: 2,  }}>
            <TextField              
              minRows={4}
              type="text"
              style={{ width: 460,  background:"#fff", }}
              className="input-style"
              placeholder=""
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={handleInputKeyDown}
              InputProps={{
                startAdornment: inputStr.map((emoji, index) => (
                
                  <span key={index} style={{ height:140,}}> 
                    {emoji.emoji}
                  </span>
                )),
                endAdornment: (
                  <InputAdornment position="end">
            <img
              className="emoji-icon"
              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
              alt=""
              onClick={() => setShowPicker(!showPicker) }
            />
           
            {showPicker && (
              <Picker
              pickerStyle={{ width: "100%", zIndex:0}}
              onEmojiClick={handleEmojiSelect}
              />
              )}
             </InputAdornment>
        ),
      }}
      />
          </Box>

          <br />
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChangeSwitch} />}
            label="Include start/end times"
            labelPlacement="start"
            sx={{ ml: 3.5, fontSize:"14px" }}
            
          />
          <br />

          {checked && (
            <Box
              display="flex"
              sx={{ ml: 3.5, mt: 2, mr: 3, justifyContent: "space-between" }}
              align="left"
            >
              <label className="dateTitle-googlePixcel">Start Date</label>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    defaultValue={today}
                    disablePast
                    views={["day", "month", "year"]}
                    className="datepicker-googlePixcel"
                  />
                </DemoItem>
                <DemoItem>
                  <TimePicker
                    defaultValue={todayEndOfTheDay}
                    disableFuture
                    className="timepicker-googlePixcel"
                  />
                </DemoItem>
              </LocalizationProvider>

              <label className="dateTitle-googlePixcel">End Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    defaultValue={today}
                    disablePast
                    views={["day", "month", "year"]}
                    className="datepicker-googlePixcel"
                  />
                </DemoItem>
                <DemoItem>
                  <TimePicker
                    defaultValue={todayEndOfTheDay}
                    disableFuture
                    className="timepicker-googlePixcel"
                  />
                </DemoItem>
              </LocalizationProvider>
            </Box>
          )}

          <Box display="flex" sx={{ ml: 3.5, mt: 2 }}>
            <Typography sx={{ mt: 1.5, mr: 2 }} variant="h6">
              Button Type (optional)
            </Typography>
            <Select
              labelId="label"
              id="selectButtonType"
              value={buttonType}
              align="left"
              onChange={handleChangeButtonType}
            
            >
              <MenuItem value="book">Book</MenuItem>
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="Shop">Shop</MenuItem>
              <MenuItem value="learnMore">Learn More</MenuItem>
              <MenuItem value="signup">Sign-up</MenuItem>
              <MenuItem value="callNow">Call Now</MenuItem>
            </Select>
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export default GoogleBusinessBox;
