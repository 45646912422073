import React from 'react';
import Picker from 'react-giphy-component';
import { useDispatch, useSelector } from "react-redux";
import { createGifState } from "../../../../redux/Slice/InputEditorState";
import {  handleUploadImage } from '../../../../redux/Slice/imageUpload';

import { Dialog } from '@mui/material';
import { handleAllUploadImage } from '../../../../redux/Slice/allImagesUpload';

function GiphyModal(
    {open,
    onClose,}
) {
    const dispatch = useDispatch();
    const { Emojis, Gifs, UploadPhotos } = useSelector(
        (state) => state.inputEditor
    );
    const seletedValue = (e) => {
        dispatch(createGifState(e.original.url))
        dispatch(handleAllUploadImage([]))
        onClose()
    }
    return (<>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Picker apiKey="qthJxQmg9dDwE68YKC5FNbh190tdsPmX" onSelected={seletedValue }  />
        </Dialog>
    </>
    )
}

export default GiphyModal;