import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import imageUpload, { handleImageLimit, handleUpdateImage, handleUploadImage } from "../../../redux/Slice/imageUpload";
import { createGifState } from "../../../redux/Slice/InputEditorState";
import './styles.css'
import { handleAllUploadImage, handleUploadVideo, handleImageLoading } from "../../../redux/Slice/allImagesUpload";
import { postUploadPhotosHandler, getMediaLibraryData } from "../../../redux/reduxThunk";
import * as api from "../../../redux/Api";
import { handleTextChange } from "../../../redux/Slice/textArea";

function SocialMediaImageUpload({ onClick, linkValue, setError, type }) {
  const { Gifs } = useSelector((state) => state.inputEditor);
  const dispatch = useDispatch();
  const { allImagesUpload, uploadVideo_url, ImageLoading } = useSelector((state) => state.allImagesUpload);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const [fileType, setFileType] = React.useState("")

  const onImageChange = async (event) => {
    const files = event.target.files
    if (files.length < 10) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
        const file = files[i];

        if (file.type.startsWith("image/")) {
          dispatch(handleImageLoading(true))
          await api.uploadImages(formData).then((res) => {

            const imageObjects = [];
            for (const imageUrl of res.data) {
              const imageObject = {
                "adminId": "",
                "mediaType": "image",
                "mediaUrl": imageUrl,
                "mediaName": imageUrl.split("/").pop(),
                "mediaDescription": "",
                "mediaTags": "",
                "mediaCategory": "",
                "mediaUsedBy": type
              };
              if (res.data?.length < 10) {
                imageObjects.push(imageObject);
              }
            }
            api.createMediaLibrary(imageObjects).then((res) => {
              if (res.status === 200) {
                const data = { "filterByType": "", "filterByUsed": "", "search": "" }
                dispatch(getMediaLibraryData(data))
                dispatch(handleImageLoading(false))
              }
            });
            dispatch(handleAllUploadImage(allImagesUpload.concat(res.data)));


          })

        } else if (file.type.startsWith("video/")) {
          dispatch(handleImageLoading(true))
          const reader = new FileReader();
          const selectedFile = event.target.files[0];
          if (selectedFile) {
            reader.readAsDataURL(selectedFile);

          }
          reader.onload = async (readerEvent) => {
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(selectedFile);
            videoElement.onloadedmetadata = async () => {
              const videoDuration = Math.floor(videoElement.duration);
              if (videoDuration > 65) {
                setError("Video duration should not be more than 1 minute")
              } else {
                setError(" ")
                dispatch(handleImageLoading(true))
                await api.uploadVideo({ "videoUrl": readerEvent.target.result }).then(async (res) => {
                  const imageObject = {
                    "adminId": "",
                    "mediaType": "video",
                    "mediaUrl": res.data,
                    "mediaName": res.data.split("/").pop(),
                    "mediaDescription": "",
                    "mediaTags": "",
                    "mediaCategory": "",
                    "mediaUsedBy": type
                  }
                  await api.createMediaLibrary(imageObject).then((res) => {
                    if (res.status === 200) {
                      const data = { "filterByType": "", "filterByUsed": "", "search": "" }
                      dispatch(handleImageLoading(false))
                      dispatch(getMediaLibraryData(data))
                    }
                  });
                  dispatch(handleUploadVideo(res.data))
                  // dispatch(getMediaLibraryData())

                })
              }

            }
          }
        }
      }
    }
    else if(files.length >= 10){
      dispatch(handleImageLimit(true))
    }
    if ([event.target.files] && [event.target.files[0]]) {
      var imagesArray = Array.from(event.target.files).map((file) =>
        URL.createObjectURL(file)
      );
    }
    // dispatch(handleUploadImage(imagesArray));
    // dispatch(handleAllUploadImage(allImagesUpload.concat(imagesArray)));
    // dispatch(createGifState(''))

  }

  useEffect(() => {
    const updatedArray = socialAccountEditorData.map(obj => {
      return { ...obj, imageUrl: allImagesUpload };
    });
    dispatch(handleTextChange(updatedArray));
  }, [allImagesUpload])
  const hiddenFileInput = React.useRef(null);

  const handleClickInput = (e) => {
    hiddenFileInput.current.click();
  };



  return (
    <Box display="flex" className="img_upload">
      <input
        type="file"
        
        style={{ fontSize: "0px", width: "0%", }}
        accept="image/*,video/*"
        className="input-button"
        ref={hiddenFileInput}
        onChange={onImageChange}
        onClick={onClick}
        multiple
        max="10"
      />

      <Box onClick={Gifs ? { disabled: true } : handleClickInput}>{linkValue}</Box>

    </Box>
  );
}

export default SocialMediaImageUpload;
