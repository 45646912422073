import React, { useEffect, useState } from "react";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updateWeekView,
  updateMonthView,
  updateDate,
  decriseDate,
  selectCalender,
} from "../../../redux/Slice/calender";
import facebooklogo from "../../../assets/images/Facebook.png";
import instagramlogo from "../../../assets/images/Instagram.png";
import linkedinlogo from "../../../assets/images/linkedin.png";
import pinterestlogo from "../../../assets/images/pinterest.png";
import twitterlogo from "../../../assets/images/twitter.png";
import googleMyBusinesslogo from "../../../assets/images/google-my-business.png";
import add from "../../../assets/add.png";
import "./index.css";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import tuneLogo from "../../../assets/images/tune.png";
import settingsLogo from "../../../assets/images/settings.png";
import moment from "moment";
import Cookies from "js-cookie";
import * as api from "../../../redux/Api";
import { getPostsData } from "../../../redux/reduxThunk";
import { setCookie, getCookie, removeCookie } from "../../../helpers/cookie";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { handleUploadVideo } from "../../../redux/Slice/allImagesUpload";
import { handleOpenScheduleDialog } from "../../../redux/Slice/scheduleDialog";
import { getTimezone } from "../../../helpers/timeZone";
import { handleSelectDate } from "../../../redux/Slice/selectDate";
import { getBrandSettingData } from "../../../redux/reduxThunk";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { buttonStyles } from "../../../Themes/buttonTheme";

export default function TopCalender() {
  const buttonClass = buttonStyles();
  const [iconsData, setIconsData] = React.useState([
    {
      id: 1,
      icon: (
        <Avatar
          className="sub-title-image"
          src={facebooklogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "activeButton",
    },
    {
      id: 2,
      icon: (
        <Avatar
          className="sub-title-image"
          src={instagramlogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "notactiveButton",
    },
    {
      id: 3,
      icon: (
        <Avatar
          className="sub-title-image"
          src={twitterlogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "notactiveButton",
    },
    {
      id: 4,
      icon: (
        <Avatar
          className="sub-title-image"
          src={linkedinlogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "notactiveButton",
    },
    {
      id: 5,
      icon: (
        <Avatar
          className="sub-title-image"
          src={pinterestlogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "notactiveButton",
    },
    {
      id: 6,
      icon: (
        <Avatar
          className="sub-title-image"
          src={googleMyBusinesslogo}
          sx={{ width: "30px", height: "30px" }}
        />
      ),
      class: "notactiveButton",
    },
  ]);
  const {
    allSocialAccountData,
    allSocialAccountLoading,
    CurrentPlanDetailsData,
    brandSettingData
  } = useSelector((state) => state.user);

  const calender = useSelector(selectCalender);
  const toDate = calender.date.clone().startOf("week");
  const fromDate = calender.date.clone().endOf("week");
  const createdBrandId = getCookie('created_brand_id')
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  const [userplanStatus, setUserPlanStatus] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState({
    status: "",
    scheduleType: "",
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDecrement = () => {
    // const payload = calender.date.add(1, 'M')
    dispatch(decriseDate(calender.weekView ? 7 : 1));
    setIsUpdated(!isUpdated);
  };

  const handleOnIncrement = () => {
    dispatch(updateDate(calender.weekView ? 7 : 1));
    setIsUpdated(!isUpdated);
  };

  useEffect(() => {
    if (
      CurrentPlanDetailsData?.data?.planDetails?.socialAccountLimit >
      allSocialAccountData?.count
    ) {
      setUserPlanStatus(true);
    }
  }, [allSocialAccountData]);
  useEffect(() => { }, [isUpdated]);
  const AddSocialAccounts = async () => {
    window.location.href = `${api.onboardUrl}/upgrade-social-brand-accounts`;
  };

  const upgradeSocialAccounts = () => {
    setCookie("billing_tab_value", 4);
    window.location.href = `${api.onboardUrl}/account-settings`;
  };

  const handleChangeFilter = (e) => {
    if (e.target.value === "all") {
      setFilterValue({ ...filterValue, [e.target.name]: "" });
    } else {
      setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    const idArray = Object.values(allSocialAccountData)
      .filter((obj) => obj && obj._id) // Remove null or undefined objects
      .map((obj) => obj._id.toString()); // Extract the _id property and convert it to a string
    let post_data = {
      socialId: idArray,
      startDate: "",
      endDate: "",
      status: filterValue.status,
      scheduleType: filterValue.scheduleType,
    };
    dispatch(getPostsData(post_data));
  }, [filterValue]);
  const handleCreatePost = () => {
    if (!('timeZone' in brandSettingData.data.settings)) {
      const timeZone = getTimezone()
      let updatedObj = {
        "timeZone": timeZone,
        "brandId": createdBrandId

      };
      api.updateSettings(updatedObj).then((res) => {
        if (res.status === 200) {
          dispatch(getBrandSettingData(createdBrandId));
        }
      })
    }
    dispatch(handleSelectDate(moment()))
    dispatch(handleOpenScheduleDialog(true));
    dispatch(handleUploadVideo(""))

  }
  return (
    <Box sx={{ background: 'white',
      borderTop: '3px solid #F8F8F8',
      borderLeft: '3px solid #F8F8F8',mt:'84px',ml:"250px"}}>
      <Grid container className="top-calender">
        <Grid xs={4} md={4}lg={3} xl={4} >
          <Box display="flex">
            <Box className="arrow-button-back">
              <ArrowBackIcon
                onClick={handleOnDecrement}
              />
            </Box>

            <Typography className="today">{calender.monthView
              ? calender.date.format("MMM YY")
              : `${toDate.format("Do MMM")}- ${fromDate.format("Do MMM")}`}</Typography>
            <Box className="arrow-button-forward">
              <ArrowForwardIcon
                onClick={handleOnIncrement}
              />
            </Box>

            {/* <Typography variant="h5" sx={{ mt: "2px", fontWeight: "600" }}>
              {calender.monthView
                ? calender.date.format("MMM YY")
                : `${toDate.format("Do MMM")}- ${fromDate.format("Do MMM")}`}
            </Typography> */}
          </Box>

        </Grid>

        <Grid xs={8} md={8} lg={6.5} xl={8} className="settings-tune" display='flex' justifyContent='space-evenly'>
          {/* <Box spacing={2} className="socia-media-logos">
            <Grid display="flex">
              {allSocialAccountLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {allSocialAccountData?.facebookPage && (
                    <Avatar
                      title={allSocialAccountData?.facebookPage?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={facebooklogo}
                    />
                  )}
                  {allSocialAccountData?.facebookGroup && (
                    <Avatar
                      title={allSocialAccountData?.facebookPage?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={facebooklogo}
                    />
                  )}
                  {allSocialAccountData?.instagram && (
                    <Avatar
                      title={allSocialAccountData?.instagram?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={instagramlogo}
                    />
                  )}
                  {allSocialAccountData?.pinterest && (
                    <Avatar
                      title={allSocialAccountData?.pinterest?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={pinterestlogo}
                    />
                  )}
                  {allSocialAccountData?.google && (
                    <Avatar
                      title={allSocialAccountData?.google?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={googleMyBusinesslogo}
                    />
                  )}
                  {allSocialAccountData?.linkedIn && (
                    <Avatar
                      title={allSocialAccountData?.linkedIn?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={linkedinlogo}
                    />
                  )}
                  {allSocialAccountData?.youtube && (
                    <Avatar
                      title={allSocialAccountData?.youtube?.pageName}
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={twitterlogo}
                    />
                  )}
                  {userplanStatus ? (
                    <Avatar
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={add}
                      onClick={AddSocialAccounts}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                      src={add}
                      onClick={upgradeSocialAccounts}
                    />
                  )}
                </>
              )}
            </Grid>
          </Box> */}
          <Box display="flex" sx={{
            padding: "4px",
            borderRadius: '8px',
            cursor: 'pointer',
            background: '#F8F8F8',
            width: '220px',
            height: '41px'
          }}>

            <Grid sx={{width:"107px",textAlign:'center', borderRadius: '8px'}} backgroundColor={calender.weekView ? " white" : "common"}>
              <Typography
                sx={{ fontSize: "14px", p: "5px 12px 5px 12px" }}

                onClick={() => dispatch(updateWeekView())}>
                Week
              </Typography>
            </Grid>

            <Grid sx={{width:"107px",textAlign:'center', borderRadius: '8px'}} backgroundColor={calender.monthView ? " white" : "common"}>
              <Typography sx={{ fontSize: "14px", p: "5px 12px 5px 12px" }}

                onClick={() => dispatch(updateMonthView())}>
                Month
              </Typography>
            </Grid>
          </Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                name="status"
                sx={{ fontSize: "14px", height: "40px" }}
                onChange={handleChangeFilter}
                defaultValue="all"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem sx={{ fontSize: "14px" }} value="all">All</MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="pending">pending</MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="published">published</MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="failed">failed</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              name="scheduleType"
              sx={{ fontSize: "14px", height: "40px" }}
              onChange={handleChangeFilter}
              defaultValue="all"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem sx={{ fontSize: "14px" }} value="all">All Posts</MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="now">Now</MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="draft">Drafts</MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="schedule">Scheduled</MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value="queue">Queue</MenuItem>

            </Select>
          </FormControl>
          <Button
            className={buttonClass.Medium_sized_Btn}
            onClick={handleCreatePost}
          >
            Create Post
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
