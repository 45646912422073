import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import greetKaroLogo from "../../assets/images/Greetkaro.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  updateDate,
  decriseDate,
  selectCalendar,
  sechedlueCalendar,
} from "../../../src/redux/Slice/schedularCalendarMonth";
import "../../components/style.css";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { handleTextChange } from "../../redux/Slice/textArea";
import "../Bottomnav/bottomNav.css";
import Queue from "../Queue";
import * as api from "../../redux/Api";
import { handleOpenScheduleDialog, handleIsEditScheduleDialog } from "../../redux/Slice/scheduleDialog";
import { getMediaLibraryData, getPostsData } from "../../redux/reduxThunk";
import { handleAllUploadImage, handleUploadVideo } from "../../redux/Slice/allImagesUpload";
import { TextField } from "@mui/material";
import { buttonStyles } from "../../Themes/buttonTheme";

function BottomNav({ event }) {
  const buttonClass=buttonStyles();
  const { draftDay } = useSelector((state) => state.draftDate)
  const [schedule, setSchedule] = React.useState(event?.postType);
  const [queue, setQueue] = React.useState(false);
  const [approve, setApprove] = React.useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [errorPost, setErrorPost] = React.useState("");
  const [error, setError] = React.useState("")
  // pass the selected date
  const [selectDate, setSelectDate] = React.useState(moment(draftDay));
  const [startDate, setStartDate] = React.useState();
  const [selectedTime, setSelectedTime] = useState(getDefaultTime());
  const [publishType, setPublishType] = React.useState("")
  const { scheduleDate } = useSelector((state) => state.schedularCalender);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { allSocialAccountData, brandSettingData } = useSelector((state) => state.user);

  // const [error, setError] = React.useState("")
  const dispatch = useDispatch();
  const { uploadVideo_url, allImagesUpload } = useSelector((state) => state.allImagesUpload);
  const { isEditScheduleDialog } = useSelector((state) => state.openScheduleDialog)
  const handleChange = (event) => {
    setSchedule(event.target.value);
  };
  const is12HourFormat = brandSettingData?.data?.settings?.timeFormat;
  const handleClickSchedule = () => {
    console.log("socialAccountEditorData", socialAccountEditorData?.map((item) => item.imageUrl.length > 0))
    const isInstagram = socialAccountEditorData?.filter((item) => item.socialAccountName === "instagram" && item.imageUrl.length > 0)
    console.log("isInstagram", isInstagram.length === 0)
    if (isInstagram.length === 0) {
      setErrorPost("Instagram Post Contain Video")
    } else {
      setErrorPost("")

    }
    setPublishType("schedule")
    setSchedule(true);
    setQueue(false);
    setOpen(true);
  };

  const handleClickQueue = () => {
    setPublishType("queue")
    setQueue(true);
    setSchedule(false);
    setOpen(true);
  };
  const handleClickDraft = () => {
    setPublishType("draft")
  };

  useEffect(() => {

    const updatedArray = socialAccountEditorData.map((obj) => {
      return { ...obj, scheduleType: publishType, publishDateTime: draftDay };
    });

    dispatch(handleTextChange(updatedArray));

  }, [publishType])

  useEffect(() => {
    if (socialAccountEditorData[0]?.scheduleType === "draft") {
      handleFinalSchedule()
    }
  }, [socialAccountEditorData[0]?.scheduleType])

  const weeks = [];

  // Get the current date
  const currentDate = new Date();
  const today = dayjs();
  const minDate = currentDate;
  const todayEndOfTheDay = today.endOf("day");
  const startOfMonth = moment(scheduleDate).startOf("month");
  const endOfMonth = moment(scheduleDate).endOf("month");
  const maxDate = new Date(endOfMonth);
  const startOfWeek = startOfMonth.clone().startOf("week");
  const endOfWeek = endOfMonth.clone().endOf("week");

  let day = startOfWeek;

  let weekIndex = 0;
  while (day <= endOfWeek) {
    weeks[weekIndex] = weeks[weekIndex] || [];
    weeks[weekIndex].push(day);
    day = day.clone().add(1, "d");
    if (day.day() === 0) {
      weekIndex += 1;
    }
  }

  // Function to check if a date is in the past
  const isPastDate = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date < yesterday;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectDate(date);
    const updatedArray = socialAccountEditorData.map((obj) => {
      return { ...obj, publishDateTime: date };
    });
    dispatch(handleTextChange(updatedArray));
  };

  const handleOnDecrement = () => {
    dispatch(decriseDate());
    setIsUpdated(!isUpdated);
  };

  const handleOnIncrement = () => {
    dispatch(updateDate());
    setIsUpdated(!isUpdated);
  };

  const handleCloseSchedule = () => {
    setSchedule(false);
  };
  const handleChangeTime = (time) => {
    setSelectedTime(time.format("HH:mm"))
  }

  const handleChangeScheduleType = (event) => {
    setSchedule(event.target.value);
    const updatedArray = socialAccountEditorData.map((obj) => {
      return { ...obj, scheduleType: event.target.value };
    });
    dispatch(handleTextChange(updatedArray));
  };
  const datetimeString = `${selectDate?.format("YYYY-MM-DD")} ${selectedTime}`;
  const datetimeMoment = moment(datetimeString, 'YYYY-MM-DD HH:mm');

  const currentTime = new Date()
  useEffect(() => {
    if (datetimeMoment.isBefore(currentTime)) {
      const updatedArray = socialAccountEditorData.map(obj => {
        return { ...obj, publishDateTime: null };
      });
      dispatch(handleTextChange(updatedArray));
      if (schedule !== 'now') {
        setError("Scheduled time is in the past");
      } else {
        const updatedArray = socialAccountEditorData.map(obj => {
          return { ...obj, publishDateTime: datetimeMoment };
        });
        dispatch(handleTextChange(updatedArray));
        setError(" ")
      }

    } else {
      const updatedArray = socialAccountEditorData.map(obj => {
        return { ...obj, publishDateTime: datetimeMoment };
      });
      dispatch(handleTextChange(updatedArray));
      setError(" ")
    }


  }, [selectedTime, selectDate, schedule])

  const allHaveImages = socialAccountEditorData.every(item => item.imageUrl?.length > 0);
  useEffect(() => {
    if (!isEditScheduleDialog) {
      if (uploadVideo_url) {
        const updatedArray = socialAccountEditorData.map(obj => {
          return { ...obj, postType: "video" };
        });
        dispatch(handleTextChange(updatedArray));
      } else {
        if (allHaveImages) {
          const updatedArray = socialAccountEditorData.map(obj => {
            return { ...obj, postType: "image" };
          });
          dispatch(handleTextChange(updatedArray));
        } else {
          const updatedArray = socialAccountEditorData.map(obj => {
            return { ...obj, postType: "text" };
          });
          dispatch(handleTextChange(updatedArray));
        }
      }
    }



  }, [allHaveImages])
  useEffect(() => {
    if (uploadVideo_url) {
      const updatedArray = socialAccountEditorData.map(obj => {
        return { ...obj, postType: "video", imageUrl: uploadVideo_url };
      });
      dispatch(handleTextChange(updatedArray));
    }
  }, [uploadVideo_url])

  const handleFinalSchedule = async () => {
    dispatch(handleOpenScheduleDialog(false));
    if (socialAccountEditorData[0]?.publishDateTime !== null) {
      if (!isEditScheduleDialog) {
        await api.createPosts(socialAccountEditorData).then((res) => {
          if (res.status === 200) {
            setOpen(false);
            dispatch(handleOpenScheduleDialog(false));
            // dispatch(getMediaLibraryData())
            let post_data;
            if (allSocialAccountData.count > 0) {
              const idArray = Object.values(allSocialAccountData)
                .filter(obj => obj && obj._id) // Remove null or undefined objects
                .map(obj => obj._id.toString()); // Extract the _id property and convert it to a string
              post_data = {
                "socialId": idArray,
                "startDate": "",
                "endDate": "",
                "status": "",
                "publishedType": ""
              }
              dispatch(getPostsData(post_data))
              dispatch(handleIsEditScheduleDialog(false))
              dispatch(handleAllUploadImage([]));
              dispatch(handleTextChange([]));
              dispatch(handleOpenScheduleDialog(false));
              dispatch(handleUploadVideo(""))

            }
          } else {
            setOpen(true);
          }
        })
      } else {
        await api.updatePosts(socialAccountEditorData).then((res) => {
          if (res.status === 200) {
            setOpen(false);
            dispatch(handleOpenScheduleDialog(false));
            // dispatch(getMediaLibraryData())
            let post_data;
            if (allSocialAccountData.count > 0) {
              const idArray = Object.values(allSocialAccountData)
                .filter(obj => obj && obj._id) // Remove null or undefined objects
                .map(obj => obj._id.toString()); // Extract the _id property and convert it to a string
              post_data = {
                "socialId": idArray,
                "startDate": "",
                "endDate": "",
                "status": "",
                "scheduleType": ""
              }
              dispatch(getPostsData(post_data))
              dispatch(handleIsEditScheduleDialog(false))
              dispatch(handleAllUploadImage([]));
              dispatch(handleTextChange([]));
              dispatch(handleOpenScheduleDialog(false));
              dispatch(handleUploadVideo(""))
            }
          } else {
            setOpen(true);
          }
        })
      }
    } else {
      setError("Places select the scheduled time is in the future")
    }


  }
  // const [selectedTime, setSelectedTime] = useState("");
  function getDefaultTime() {
    const now = new Date();
    const future = new Date(now.getTime() + 2 * 60000)
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = future.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  return (
    <Box align="right">
      <Grid
        xs={5}
        md={5}
        lg={5}
        sx={{ display: "flex", justifyContent: "end", mt: "20px", mb: "20px",mr:2 }}
      >
        {socialAccountEditorData && (socialAccountEditorData[0]?.message || socialAccountEditorData[0]?.imageUrl?.length > 0) ?
          <Grid>
            <Button className={buttonClass.Grey_color_Btn} sx={{mr:1}} onClick={handleClickDraft}>Draft</Button>
            <Button className={buttonClass.Blue_color_Btn} sx={{mr:1}} onClick={handleClickSchedule}>
              Schedule
            </Button>
          </Grid>
          : null}


        {/* <Button className="bottom_button" disabled onClick={handleClickQueue}>
          Queue
        </Button> */}

        {schedule &&
          <Dialog onClose={handleClose}  open={open} sx={{ padding: "40px 10px 40px 20px" }} >
            <CardContent>
              <Box display="flex" justifyContent="space-between" sx={{ marginLeft: "23px", marginRight: "53px" }}>
                <Typography variant="h5" sx={{ fontWeight: 900, fontSize:"24px" }}>
                  {scheduleDate.format("MMMM YY")}
                </Typography>
                <Box sx={{cursor:"pointer"}}>
                  <ArrowLeftOutlinedIcon onClick={handleOnDecrement} />
                  <ArrowRightOutlinedIcon onClick={handleOnIncrement} />
                </Box>
              </Box>
              <Box width="90%" align="right">
                <div >
                  <table className="schedular-calendar">
                    <thead height="50px">
                      <tr className="t-head-card">
                        <th className="th-card">Su</th>
                        <th className="th-card">Mo</th>
                        <th className="th-card">Tu</th>
                        <th className="th-card">We</th>
                        <th className="th-card">Th</th>
                        <th className="th-card">Fr</th>
                        <th className="th-card">Sa</th>
                      </tr>
                    </thead>
                    <tbody className="schedule-table-body">
                      {weeks.map((week, index) => (
                        <tr key={index}>
                          {week.map((day) => {
                            return (
                              <td
                                onClick={() => handleDateChange(day)}
                                className={
                                  isPastDate(day)
                                    ? ""
                                    : moment(
                                      selectDate.format("YYYY-MM-DD")
                                    ).isSame(day.format("YYYY-MM-DD"))
                                      ? "selected-schedule"
                                      : "td-card-schedule"
                                }
                                key={day.format()}
                                id={`${isPastDate(day)
                                  ? "disabled-schedular"
                                  : ""
                                  }`}
                              >
                                <Box className="date-month-card">
                                  <Box
                                    min={minDate}
                                    max={maxDate}
                                    className="date-card"
                                  >
                                    {day.format("D")}
                                  </Box>
                                </Box>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Box>

              <br />
              <Box display="flex" sx={{ marginBottom: "10px" }}>
                <Box>
                  <Typography sx={{ mt: 2, ml: 3 }} variant="h5">
                    Time Slot:
                  </Typography>{" "}
                  <br />
                  <TextField
                    className="time_input"
                    sx={{ ml: 3,cursor:"pointer" }}
                    type="time"
                    defaultValue={selectedTime}
                    value={selectedTime}
                    onChange={handleTimeChange}
                    InputLabelProps={{
                      shrink: true,
                      cursor:"pointer"

                    }}
                    inputProps={{
                      step: 300, // 5 min
                      style: { fontSize: '18px', height:"12px",cursor:"pointer", display: 'flex', alignItems: 'center' }
                    }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ mt: 2, ml: 3 }} variant="h5">
                    Schedule Type:
                  </Typography>
                  <FormControl sx={{ mt: 2, ml: 3, width: "176px" }}>
                    <Select
                      defaultValue="schedule"
                      value={event?.scheduleType}
                      onChange={handleChangeScheduleType}
                      inputProps={"aria-label"}
                      sx={{ fontSize: "18px", height:"44px" }}
                    >
                      <MenuItem value="now" sx={{ fontSize: "18px" }}>Now</MenuItem>
                      <MenuItem value="schedule" sx={{ fontSize: "18px" }}>Schedule</MenuItem>
                      {/* <MenuItem value="approve" sx={{ fontSize: "20px" }}>Approve</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Typography sx={{ color: "red", marginLeft: "22px", fontSize: "14px" }}>{error}</Typography>
              {/* <Typography sx={{ color: "red", marginLeft: "22px", fontSize: "14px" }}>{errorPost}</Typography> */}
              <br />

              <Box
                sx={{
                  display: "flex",
                  ml: 3,
                  mr: 4,
                  justifyContent: "end",
                }}
              >
                <Button
                 className={buttonClass.Grey_small_Btn}
                 sx={{mr:"12px"}}
                  onClick={handleCloseSchedule}
                >
                  Cancel
                </Button>
                <Button
                className={buttonClass.Medium_sized_Btn}
                  onClick={handleFinalSchedule}
                >
                  Post
                </Button>
              </Box>
            </CardContent>
          </Dialog>
        }
        {/* {queue && <Queue />} */}
      </Grid>
    </Box>
  );
}

export default BottomNav;
