import axios from "axios";
import Cookies from 'js-cookie';
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie';

const baseURL =
  process.env.NODE_ENV === "production" ? "https://api.socialxperia.ai/api" : "http://localhost:8080/api";

  console.log("node_env",process.env,process.env.REACT_APP_onboardUrl)
  let onboardUrl, scheduleUrl, editorUrl, analyticsUrl;
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  } else {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  }
  
  export { onboardUrl, scheduleUrl, editorUrl, analyticsUrl };
  const API = axios.create({ baseURL });
  API.interceptors.request.use(
    (config) => {
      const token = getCookie('token');
      if (token) {
        config.headers['token'] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const uploadConfig={
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }
  
  export default API;
  export const getUser = () => API.get(`/user`);
  export const getCurrentPlanDetailsApi = () => API.get('/get-current-plan');

  //get all user brands
  export const getUserBrands = () =>API.get('/get-user-brands');
  export const getBrandAll = () => API.get(`/get-all-brands`);
  export const getBrandSettings = (id) =>API.get(`/brand-settings?brand=${id}`);
  export const getAllSocialAccounts = (data) =>API.get(`/social-accounts?brand_id=${data}`);

  // Account info 
  export const accountSettingsApi = () => API.get(`/account-settings`);

  //create post 
  export const createPosts=(data)=>API.post('/schedule-posts',data)
  export const getPosts=(data)=>API.post(`/get-schedule-posts`,data)
  export const updatePosts=(data)=>API.put(`/schedule-posts`,data)
  export const deletePosts=(id)=>API.delete(`/schedule-posts/${id}`)

  // upload images
  export const uploadImages=(data)=>API.post('/upload-multi-image',data,uploadConfig)
  export const deleteUploadImages=(data)=>API.delete(`/delete-image?imageurl=${data}`)
  export const getUploadImages=()=>API.get('/uploadimages')

  // upload video
  export const uploadVideo=(data)=>API.post('/upload-video',data)

  // media library
  export const createMediaLibrary=(data)=>API.post('/create-media-library',data)
  export const getMediaLibrary=(data)=>API.get(`/get-all-media-library?filterByType=${data.filterByType}&filterByUsed=${data.filterByUsed}&search=${data.search}`)
  export const updateMediaLibrary=(data)=>API.put(`/update-media-library`,data)
  export const deleteMediaLibrary=(data)=>API.delete(`/delete-media-library`,{ data: { items: data } })

// brand apis
export const updateSettings = (data) =>
  API.post(`/brand-settings?brand=${data.brandId}`, data);

//Ai APIS 
export const generateTitle = (data) =>API.post(`/generate-title`, data);
export const generateHashTags = (data) =>API.post(`/generate-hashtags`, data);
