import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    date : moment(),
    weekView: true,
    monthView: false,
    isUpdated: true
};

export const calenderSlice = createSlice({
  name: "calender",
  initialState,
  reducers: {
    updateWeekView: (state) => {
      state.weekView = true;
      state.monthView = false;
    },
    updateMonthView: (state) => {
        state.weekView = false;
        state.monthView = true;
    },
    updateDate: (state, action) => {
        state.date = state.date.add(action.payload, state.weekView?'d':'M')
        // state.date.add(1, 'M')
        state.isUpdated = !state.isUpdated
    },
    decriseDate: (state, action) => {
      state.date = state.date.subtract(action.payload, state.weekView?'days':'months')

      state.isUpdated = !state.isUpdated
  }
  }
});

// Action creators are generated for each case reducer function
export const {
    updateWeekView, updateMonthView, updateDate, decriseDate
} = calenderSlice.actions;

export const selectCalender = (state) => state.calender

export default calenderSlice.reducer;
