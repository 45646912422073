import { configureStore } from "@reduxjs/toolkit";
import calenderReducer from "./Slice/calender";
import schedularCalendarReducer from "./Slice/schedularCalendarMonth";
import IconsActionReducer from "./Slice/iconsState";
import InputEditorState from "./Slice/InputEditorState";
import imageUploadReducer from "./Slice/imageUpload";
import documentUploadReducer from "./Slice/documentUpload";
import textAreaReducer from "./Slice/textArea";
import selectDateReducer from "./Slice/selectDate"
import UnsplashReducer from "./Slice/unSplash";
import libraryImageReducer from "./Slice/mediaLibrary"
import userReduser from "./Slice/user";
import allImagesUploadReducer from "./Slice/allImagesUpload";
import postData from "./Slice/post";
import openScheduleDialogReducer from "./Slice/scheduleDialog" 

export const store = configureStore({
  reducer: {
    calender: calenderReducer,
    schedularCalender: schedularCalendarReducer,
    inputEditor:InputEditorState,
    IconsAction:IconsActionReducer,
    imageUpload:imageUploadReducer,
    documentUpload:documentUploadReducer,
    textAreaval:textAreaReducer,
    Unsplash:UnsplashReducer,
    user: userReduser,
    libraryImage:libraryImageReducer,
    allImagesUpload:allImagesUploadReducer,
    post:postData,
    draftDate:selectDateReducer,
    openScheduleDialog:openScheduleDialogReducer,
  },
  middleware: (getDefaultMiddleware) =>   
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


