import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    scheduleDate : moment()
};

export const schedualrCalendarSlice = createSlice({
  name: "Schedularcalender",
  initialState,
  reducers: {
    updateDate: (state) => {
        state.scheduleDate = state.scheduleDate.add(1, 'M')

    },
    decriseDate: (state) => {
      state.scheduleDate = state.scheduleDate.subtract( 1, 'months')


  }
  }
});

// Action creators are generated for each case reducer function
export const { updateDate, decriseDate} = schedualrCalendarSlice.actions;

export const sechedlueCalendar = (state) => state.Schedularcalender

export default schedualrCalendarSlice.reducer;
