import * as React from "react";
import { Box, Divider, Typography ,Grid} from "@mui/material";
import EditorComp from "../../EditorComp";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import linkedinlogo from "../../../../assets/images/linkedin.png";
import "../iconsBox.css";
import { useSelector } from "react-redux";

const LinedInBox = ({ register }) => {
  const { allSocialAccountData } = useSelector((state) => state.user);

  return (
    <Grid className="events-box">
      <Box display="flex">
        <img className="sub-title-image-card" alt="" src={linkedinlogo} />
        <Typography variant="header2" className="sub-title-card">{allSocialAccountData?.linkedin?.pageName}</Typography>
      </Box>
      <Divider orientation="middle" flexItem />
      <EditorComp textAreaName={"linkedIn"} register={register} />
    </Grid>
  );
};

export default LinedInBox;
