import * as React from "react";
import { Box, Divider, Typography,Grid } from "@mui/material";
import EditorComp from "../../EditorComp";
import pinterestlogo from "../../../../assets/images/pinterest.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "../iconsBox.css"
import { useSelector } from "react-redux";

const PinterestBox = ({ register }) => {
  const { allSocialAccountData } = useSelector((state) => state.user);

  return (
    <Grid className="events-box">
      <Box display="flex">
        <img className="sub-title-image-card" alt="" src={pinterestlogo} />
        <Typography variant="header2" className="sub-title-card">{allSocialAccountData?.pinterest?.pageName}</Typography>
      </Box>
      <Divider orientation="middle" flexItem />
      <EditorComp textAreaName={"twitter"} register={register} />
      <Box align="left">
        <Box>
          <label className="pintrest-titles">Title</label>
          <br/>
          <input type="text" id="pintrest-textareas"  />
        </Box>
        <Box>
          <label className="pintrest-titles">Destination Link</label>
          <br />
          <input type="text"  id="pintrest-textareas"  />
        </Box>
        <Box>
          <label className="pintrest-titles">Select Board</label>
          <br />
          <input type="text"  id="pintrest-textareas" />
        </Box>
      </Box>
    </Grid>
  );
};

export default PinterestBox;
