import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBrandSettings,
  getAllSocialAccounts,
  getUserBrands,
  getBrandAll,
  getUser,
  getCurrentPlanDetailsApi,
  getPosts,
  getMediaLibrary
} from '../Api';

export const getBrandSettingData = createAsyncThunk(
  'Brand/brand-settings',
  async (id) => await getBrandSettings(id)
);

export const getAllSocialAccountData = createAsyncThunk(
  'Brand/social-accounts',
  async (id) => await getAllSocialAccounts(id)
);

export const getAllBrandData = createAsyncThunk(
    'Brand/get-all-brands',
    async (accountType) => {
      if (accountType === 'USER') {
        return await getUserBrands();
      } else {
        return await getBrandAll();
      }
    }
  );
  export const getUserData = createAsyncThunk(
    'User/user',
    async () => await getUser()
  );
  export const getCurrentPlanDetailsApiData = createAsyncThunk(
    'User/get-current-plan',
    async () => await getCurrentPlanDetailsApi()
  );
  export const getPostsData = createAsyncThunk(
    'post/get-schedule-posts',
    async (id) => await getPosts(id)
  );
  
  export const getMediaLibraryData = createAsyncThunk(
    'get/get-all-media-library',
    async (data) => await getMediaLibrary(data)
  );