import React, { useState, useEffect } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Settings from '@mui/icons-material/Settings';
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Grid
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../redux/Api';
import {
  getBrandSettingData
} from '../../redux/reduxThunk';
import { CalendarMonth } from '@mui/icons-material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import { setCookie, getCookie } from '../../helpers/cookie';
import { sidebarStyles } from "../../Themes/sideBarTheme";
function Sidebar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const sidebarClass = sidebarStyles();
  const createdBrandId = getCookie('created_brand_id');
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sidebar_activeItem = getCookie('sidebar_activeItem')
  const handleListData = (value) => {
    setCookie('sidebar_activeItem', value);
    switch (value) {
      case 'Calendar':
        navigate('/calendar')
        break;
      case 'Media Library':
        navigate('/medialibrary')
        dispatch(getBrandSettingData(createdBrandId));
        break;
      case 'Reports':
        window.location.href = `${api.analyticsUrl}/reports`;
        break;
      case 'Conversations':
        window.location.href = `${api.analyticsUrl}/conversations`;
        break;
      case 'Social Accounts':
        window.location.href = `${api.onboardUrl}/social-account-settings`;
        break;
      case 'Members':
        window.location.href = `${api.onboardUrl}/members`;
        break;
      case 'Brand settings':
        window.location.href = `${api.onboardUrl}/brand-settings`;
        dispatch(getBrandSettingData(createdBrandId));
        break;
      case 'Add Brand':
        localStorage.setItem('activeStep', 0);
        window.location.href = `${api.onboardUrl}/social-brand-accounts`;
        break;
      case 'Upgrade Brand':
        setCookie('billing_tab_value', 3);
        window.location.href = `${api.onboardUrl}/account-settings`;
        break;
      case 'Account settings':
        setCookie('billing_tab_value', 0);
        window.location.href = (`${api.onboardUrl}/account-settings`);
        break;
      default:
        break;
    }
  }

  return (
    <Grid className={sidebarClass.sidebar_main}>
      <List >
        {[
          { name: 'Calendar', icon: <CalendarMonth /> },
          { name: 'Media Library', icon: <AddPhotoAlternateIcon /> },
          { name: 'Reports', icon: <AnalyticsIcon /> },
          { name: 'Conversations', icon: <TextsmsIcon /> },
          { name: 'Social Accounts', icon: <AccountCircleOutlinedIcon /> },
          { name: 'Members', icon: <PeopleIcon /> },
          { name: 'Brand settings', icon: <Settings /> },
          { name: 'Account settings', icon: <ManageAccountsIcon /> }
        ].map((item, index) => (
          <ListItem
            key={index}
            sx={{ padding: "8px" }}
            onClick={() => handleListData(item.name)}
          >
            <ListItemButton disableRipple
              sx={{
                backgroundColor: sidebar_activeItem === item.name ? "#7a40ed" : "transparent",
                "&:hover": {
                  backgroundColor: sidebar_activeItem === item.name ? "#7a40ed" : "transparent",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 35, ml: 1, color: sidebar_activeItem === item.name ? 'white' : 'inherit', }}>
                {item.icon}
              </ListItemIcon>
              <Typography variant="h5" sx={{ color: sidebar_activeItem === item.name ? 'white' : '#383333' }}>
                {item.name}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid >
  );
}

export default Sidebar;
