import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";

import greetKaroLogo from "../../../assets/images/Greetkaro.png";
import facebooklogo from "../../../assets/images/Facebook.png";
import { useSelector } from "react-redux";
import previewimage from "../../../assets/images/image.png";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import ScreenShareRoundedIcon from "@mui/icons-material/ScreenShareRounded";
import "./style.css";

function FacebookGroupPreview() {
  const { allSocialAccountData } = useSelector((state) => state.user);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { Gifs } = useSelector((state) => state.inputEditor);
  const { allImagesUpload,uploadVideo_url } = useSelector((state) => state.allImagesUpload);

  const hasMoreImages = allImagesUpload.length > 4;
  const remainingImagesCount = allImagesUpload.length - 4;


  return (
    <Box>
      <Card sx={{ m: 4 }}>
        <Box display="flex" className="preview-box">
          <Box display="flex">
            <Avatar
              size="sm"
              src={allSocialAccountData?.facebookGroup?.profileImage}
              sx={{
                marginRight: "10px",
                border: "1px solid",
                width: "43px",
                height: "42px",
                borderColor: "background.body",
              }}
            />
            <Typography variant="header2" sx={{margin: "10px 0px 0px 0px"}}>{allSocialAccountData?.facebookGroup?.pageName}</Typography>
          </Box>
          <img className="sub-title-image" src={facebooklogo} alt="" />
        </Box>
        <CardContent>
          {socialAccountEditorData && socialAccountEditorData?.filter((item)=>item.socialAccountName ==="facebookGroup")
          .map((item) => (
            <Typography
              variant="h6"
              className="preview-card-text"
              key={item.id}
            >
              {item.message}
            </Typography>
          ))}
        </CardContent>
        <Box align="center" sx={{ justifyContent: "left" }}>
        {uploadVideo_url && <video height="100%" width="100%" controls src={uploadVideo_url}></video>}
          <ImageList
            cols={allImagesUpload.length === 1 ? 1 : 2}
            gap={2}
            // variant="masonry"
            sx={{ overflowY: "hidden", overflowX: "hidden" }}
          >
            {allImagesUpload?.slice(0, 4).map((image, index) => (
              <Box>
                <div className="post-preview-image" key={index}>
                  <ImageListItem>
                    <img
                      key={image.index}
                      src={image}
                      alt="imges"
                      style={{
                        height:
                          allImagesUpload.length === 1 ? "400px" : "200px",
                        objectFit: "cover",
                      }}
                    />
                  </ImageListItem>
                  {index === 3 && hasMoreImages && (
                    <div className="add-button-container">
                      <button className="add-button-preview">
                        +{remainingImagesCount}
                      </button>
                    </div>
                  )}
                </div>
              </Box>
            ))}
          </ImageList>
          {Gifs && (
            <div>
              <img src={Gifs} alt="gif" width="100%" />
            </div>
          )}
        </Box>
        <Box>
          <CardActions
            aria-label="add to favorites"
            size="sm"
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button className="facebook-preview-buttons">
              <ThumbUpOffAltIcon />
              <Typography variant="h5">Like</Typography>
            </Button>
            <Button className="facebook-preview-buttons">
              <InsertCommentRoundedIcon />
              <Typography variant="h5">Comment</Typography>
            </Button>

            <Button className="facebook-preview-buttons">
              <ScreenShareRoundedIcon />
              <Typography variant="h5">Share</Typography>
            </Button>
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
}

export default FacebookGroupPreview;
