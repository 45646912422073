import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { selectCalender } from "../../../redux/Slice/calender";
import {
  Avatar,
  Box,
  Button,
  Typography,
  Slide,
  Grid
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EventCard from "../../../components/EventCard";
import Tooltip from "@mui/material/Tooltip";
import ScheduleDialog from "../../../components/ScheduleDialog";
import { handleOpenScheduleDialog } from "../../../redux/Slice/scheduleDialog";
import { handleSelectDate } from "../../../redux/Slice/selectDate";
import { handleUploadVideo } from "../../../redux/Slice/allImagesUpload";
import * as api from "../../../redux/Api";
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie';
import { getTimezone } from "../../../helpers/timeZone";
import { getBrandSettingData } from "../../../redux/reduxThunk";
import NestedAvatar from "../../../components/common/NestedAvatar"

function WeekComponent({ date, events }) {
  const { postData } = useSelector((state) => state.post);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const dispatch = useDispatch()
  const { openScheduleDialog } = useSelector((state) => state.openScheduleDialog);
  const { brandSettingData } = useSelector((state) => state.user);
  const calender = useSelector(selectCalender);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState()
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const days = [];
  const startOfWeek = moment(date).startOf("week");
  const endOfWeek = moment(date).endOf("week");
  // Get the current date
  const currentDate = new Date();
  const createdBrandId = getCookie('created_brand_id')
  let day = startOfWeek;
  while (day <= endOfWeek) {
    days.push(day);
    day = day.clone().add(1, "d");
  }

  const startTime = moment().startOf("day").hour(0); // set start time to 12:00 AM
  const endTime = moment().startOf("day").hour(23).minute(30); // set end time to 11:30 PM
  const timeArray = [];

  for (let m = moment(startTime); m.isSameOrBefore(endTime); m.add(30, "minutes")) {
    timeArray.push(m.format("h:mm a"));
  }

  const handleClickOpen = (day, hour) => {
    const datetimeString = `${day?.format("YYYY-MM-DD")} ${hour}`;
    const datetimeMoment = moment(datetimeString, 'YYYY-MM-DD HH:mm');
    const formattedDatetimeString = datetimeMoment.format('YYYY-MM-DD h:mm a');

    if (!('timeZone' in brandSettingData?.data?.settings)) {
      const timeZone = getTimezone()
      let updatedObj = {
        "timeZone": timeZone,
        "brandId": createdBrandId

      };
      api.updateSettings(updatedObj).then((res) => {
        if (res.status === 200) {
          dispatch(getBrandSettingData(createdBrandId));
        }
      })
    }
    dispatch(handleSelectDate(formattedDatetimeString))
    dispatch(handleOpenScheduleDialog(true));
    dispatch(handleUploadVideo(""))
  };


  const isPastDate = (date, hour) => {
    const currentDateTime = moment().minutes(Math.round(moment().minutes() / 30) * 30);
    const cellDateTime = moment(`${date.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD h:mm a").minutes(Math.round(moment(`${date.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD h:mm a").minutes() / 30) * 30);

    const isPastDate = cellDateTime.isBefore(moment().startOf('day'));
    const isPastTime = currentDateTime.isSame(date, 'day') && cellDateTime.isBefore(currentDateTime);

    return isPastDate || isPastTime;
  };
  const handleTooltipOpen = (event) => {
    setCurrentEvent(event);
    if (openScheduleDialog) {
      setIsTooltipOpen(false);
      setIsEditOpen(false)
    } else {
      setIsTooltipOpen(true);
      setIsEditOpen(false);
    }

  };
  return (
    <div className="week-calender" >
      <div className="week-component">
        <table className="week-table">
          <thead className="week-thead">
            <tr>
              <th className="week-th"></th>
              {days.map((day) => (
                <>
                  <th className="week-th" key={day.format()}>
                    <Grid>
                      <Typography>{day.format("ddd")}</Typography>
                      <Typography sx={{fontSize:"22px"}}>{day.format("DD")}</Typography>
                    </Grid>
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody className="week-tbody" >
            {timeArray.map((hour) => (
              <tr key={hour}>
                <td className="hour-column">
                  {hour}
                </td>
                {days.map((day) => {
                  const event = postData.find(
                    (e) =>
                      moment(e.publishDateTime).isSame(day, "day") &&
                      moment(e.publishDateTime).startOf('hour').add(Math.round(moment(e.publishDateTime).minute() / 30) * 30, 'minutes').format('h:mm a') === hour
                  );
                  return (
                    <td key={day.format() + hour} className="event-cell" id={`${isPastDate(day, hour) ? "disabled" : ""}`} >
                      <Box className="overlay" >
                        <Button
                          className={calender.weekView ? "addIcon-week" : "addIcon-month"}
                          variant="outlined"
                          title="Add post"
                          onClick={() => handleClickOpen(day, hour)}>
                          <AddIcon />
                        </Button>
                      </Box>

                      {event && (
                        <div>
                          <Tooltip title={<EventCard event={event} setIsEditOpen={setIsEditOpen} />}
                            open={isTooltipOpen && !isEditOpen && currentEvent === event}
                            onClick={() => handleTooltipOpen(event)}
                            onClose={() => setIsTooltipOpen(false)}
                          >
                            <Box
                              key={event.title}
                              display=" flex"
                              width='123px'
                              // height="50px"
                              justifyContent="space-around"
                              alignItems="center"
                              backgroundColor="antiquewhite"
                              p="8px 5px 8px 5px"
                              sx={{ borderRadius: "5px" ,borderLeft:"2px solid"}}
                              onMouseEnter={() => handleTooltipOpen(event)}
                            >
                              <NestedAvatar
                                // key={index}
                                sx={{ height: "32px !important", width: "32px important" }}
                                src1={event?.profileImage}
                                src2={event?.socialImage}

                              />
                              {/* <Avatar
                                src={event?.profileImage}
                                sx={{ width: "40px", height: "40px" }}
                              /> */}
                              <Typography sx={{ fontSize: "14px" }}>{moment(event?.publishDateTime).format('h:mm a')}  </Typography>

                              {/* <img
                                src={event?.socialImage}
                                width="9.9px"
                                height="9.9px"
                                background-color="#fff"
                                alt=""
                              /> */}
                            </Box>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {openScheduleDialog && <ScheduleDialog event={null} />}
      </div>
    </div>
  );
}

export default WeekComponent;
