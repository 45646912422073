
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Navigation from "../index";
export default function   Calender({ register }) {
  const { calender } = useSelector((state) => state);

  return (
    <>
    <Navigation title = {"Calendar"}  />
    </>
  );
}
