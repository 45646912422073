import { makeStyles } from '@mui/styles';

export const buttonStyles = makeStyles({
    submit_button: {
        height: '50px',
        width: '400px',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: 'black !important',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight:'600',
        textTransform:'capitalize !important',
        '@media (min-width: 1800px)': {
          width: 700,
          height: 60,
        },
        '@media (min-width: 1600px)': {
          width: 600,
          height: 60,
        },
        '@media (max-width: 1226px)': {
          width: 400,
          height: 45,
        },
        '@media (max-width: 1000px)': {
          width: 300,
          height: 40,
        },
        '@media (max-width: 768px)': {
          width: 300,
          height: 35,
        },
    
      },
      Big_sized_Btn: {
        height: '56px',
        padding:'16px 32px',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: 'black !important',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight:'600',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Medium_sized_Btn: {
        height: '40px',
        width: '100px',
        padding:'16px 32px',
        border:'solid 1px #2e25f1 !important',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: '#2e25f1 !important',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight:'600',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Small_sized_Btn: {
        height: '40px',
        padding:'12px 24px',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: '#2e25f1 !important',
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight:'600',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      purple_color_Btn: {
        height: '48px',
        width:'250px',
        padding:'16px 32px',
        border:'solid 1px #7a40ed !important',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: '#7a40ed !important',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight:'600',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Red_color_Btn: {
        height: '48px',
        padding:'16px 32px !important',
        borderRadius: '8px !important',
        color: 'white !important',
        backgroundColor: '#d61a1a !important',
        cursor: 'pointer',
        fontSize: '14px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Grey_color_Btn: {
        height: '48px',
        padding:'8px 16px !important',
        border:'solid 1px #979797 !important',
        borderRadius: '8px !important',
        color: '#646464 !important',
        backgroundColor: 'white !important',
        cursor: 'pointer',
        fontSize: '14px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Grey_small_Btn: {
        height: '40px',
        padding:'8px 16px !important',
        border:'solid 1px #979797 !important',
        borderRadius: '8px !important',
        color: '#646464 !important',
        cursor: 'pointer',
        fontSize: '12px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Blue_color_Btn: {
        height: '48px',
        padding:'16px 32px !important',
        border:'solid 1px #2e25f1 !important',
        borderRadius: '8px',
        color: 'white !important',
        backgroundColor: '#2e25f1 !important',
        cursor: 'pointer',
        fontSize: '16px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Blue_Color_Outlined_Btn: {
        height: '48px',
        padding:'16px 32px !important',
        border:'solid 2px #2e25f1 !important',
        borderRadius: '8px !important',
        color: '#2e25f1 !important',
        backgroundColor: '#ffffff !important',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
      Blue_color_disabled_Btn: {
        height: '48px',
        padding:'16px 32px !important',
        border:'solid 1px 9692f8 !important',
        borderRadius: '8px ',
        color: 'white !important',
        backgroundColor: '#9692f8 !important',
        cursor: 'pointer',
        fontSize: '16px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
        boxShadow:'none',
        '&:hover': {
          boxShadow:'none',
        }
      },
    
      disabledSubmitBtn:{
        backgroundColor: '#4b4a4a !important',
        height: '48px !important',
        padding:'16px 32px !important',
        borderRadius: '8px !important',
        color: 'white !important',
        cursor: 'pointer',
        fontSize: '14px !important',
        fontWeight:'600 !important',
        textTransform:'capitalize !important',
    },
});


