import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './aiStyles.css'
import { buttonStyles } from '../../Themes/buttonTheme';
import Checkbox from '@mui/material/Checkbox';
import { handleTextChange } from "../../redux/Slice/textArea";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../redux/Api";
function HashTagGeneratorDialog({
    open,
    onClose,
    setOpenAi
}) {
    const dispatch = useDispatch();
    const [hashtags, setHashtags] = React.useState("");
    const [hashtagsData, setHashTagsData] = React.useState([])
    
    const [hashtagcheckBoxData, setHashtagcheckBoxData] = React.useState(false);
    const [postData, setPostData] = React.useState()
    const [addButton, setAddButton] = React.useState(false);
    const [Loader, setLoader] = React.useState(false);
    const buttons = buttonStyles();
    const { socialAccountEditorData } = useSelector((state) => state.textAreaval);

    const handleChangeInputVal = (e) => {
        setHashtags(e.target.value)
    }

    const fetchHashTagData = async () => {
        setLoader(true)
        await api.generateHashTags({
            "text": hashtags
        }).then((res) => {
            if(res.status==200){
                setHashtagcheckBoxData(true)
              }
            console.log('setHashTagsData', res.data);
            setLoader(false)
            setHashTagsData(res.data)
            //   console.log('setHashTagsData',hashtagsData);
        })
    };
    const [checkedItems, setCheckedItems] = React.useState("")
    const handleCheckboxChange = (item) => (event) => {
        const isChecked = event.target.checked;
        if(event.target.checked===true){
            setAddButton(true)
          }
          else
          {
              setAddButton(false)
          }
        // Update the checked value in the state
        setCheckedItems((prevCheckedItems) => {
            const updatedCheckedItems = { ...prevCheckedItems };
            updatedCheckedItems[item] = isChecked;
            return updatedCheckedItems;
        });

        // Update the post data with the selected values
        setPostData((prevPostData) => {
            const updatedPostData = prevPostData?.map((obj) => {
                let newMessage = obj.message || '';
                if (isChecked) {
                    // Append the selected value to the existing message string
                    newMessage += ' ' + item;
                } else {
                    // Remove the selected value from the existing message string
                    newMessage = newMessage?.replace(item, '').trim();
                }
                return { ...obj, message: newMessage };
            });
            return updatedPostData;
        });
    };

    // Display the selected text
    const selectedText = Object.keys(checkedItems)
        .filter((item) => checkedItems[item])
        .join(' ');

    const handlePostData = () => {
        const updatedArray = socialAccountEditorData.map(obj => {
            let newMessage = obj.message || '';
            // Append the selected value to the existing message string
            newMessage += ' ' + selectedText;
            return { ...obj, message: newMessage };
          });
        dispatch(handleTextChange(updatedArray));
        onClose()
        setHashTagsData([])
        setHashtagcheckBoxData(false)
    }
    const hashtagsArray = hashtagsData && hashtagsData?.hashtags?.split('#')
    .filter(str => str.trim() !== '')
    .map(str => `#${str.trim()}`)
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}>
                <>
                    <Grid className='title-grid'>
                        <Typography className='AI-title'>
                            Hashtag Generator
                        </Typography>
                        <IconButton onClick={onClose} disableRipplex>
                            <CloseIcon sx={{ height: '24px', width: "24px", color: '#000' }} />
                        </IconButton>
                    </Grid>
                    <Grid className='Ai-Form'>
                        <TextField
                            onChange={handleChangeInputVal}
                            className='hashTagInput'
                            inputProps={{ style: { fontSize: 16, padding: '12px 16px' } }}
                            placeholder='Enter your hashtag here' />
                            
                        <Button onClick={fetchHashTagData} className={buttons.Blue_Color_Outlined_Btn}>
                            Generate
                        </Button>
                        
                    </Grid>
                    {Loader ? <Grid display='flex' justifyContent='center' className='Ai-selection-Box'><CircularProgress /></Grid>  :
                            <>
                    {hashtagcheckBoxData && <>
                    <Grid className='Ai-selection-Box'>
                        
                                <Grid container spacing={2} mb={1}>
                                    {hashtagsArray?.map((item, index) => (
                                        <Grid item xs={6} key={index}>
                                            <Grid display="flex" alignItems='center'>
                                                <Checkbox onClick={handleCheckboxChange(item)} />
                                                <Typography variant="h5" >{item}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                           
                        {addButton ? 
                            <Grid className='submit_button_grid'>
                                <Button className={buttons.Blue_color_Btn} onClick={handlePostData}>
                                    Add to post
                                </Button>
                            </Grid>
                            :
                            <Grid className='submit_button_grid'>
                            <Button 
                              className={buttons.Blue_color_disabled_Btn}>Add to post</Button>
                          </Grid>}

                    </Grid>
                    </>}
                    </>
                        }
                </>
            </Dialog>
        </>
    )
}

export default HashTagGeneratorDialog
