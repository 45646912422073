import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socialAccountEditorData: [],
  message:""
};

export const textAreaSlice = createSlice({
  name: "textArea",
  initialState,
  reducers: {
    handleTextChange: (state, action) => {
      state.socialAccountEditorData = action.payload;
    },
    handleMessageChange: (state, action) => {
      state.message = action.payload;
    },

  },
});
// Action creators are generated for each case reducer function
export const { handleTextChange,handleMessageChange } =
  textAreaSlice.actions;

export default textAreaSlice.reducer;
