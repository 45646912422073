
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Navigation from "../index";
export default function   index({ register }) {
 

  return (
    <>
    <Navigation title = {"Media Library"}  />
    </>
  );
}
