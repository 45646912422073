import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import { handleUnsplashPhotos } from "../../../../redux/Slice/unSplash";
import "./unSplash.css";
import {
  handleAllUploadImage,
  handleDeleteAllImage,
} from "../../../../redux/Slice/allImagesUpload";
import { handleTextChange } from "../../../../redux/Slice/textArea";
import { CircularProgress, Skeleton } from '@mui/material';

const API_KEY = "lSMnusytpDWH3DiaG-CDWiSqXbsLpJp6wteg5P4hJc0";
const maxWidth = "xl";

function UnsplashModal({ open, onClose }) {
  const [photos, setPhotos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [imagesSelected, setImagesSelected] = useState([]);
  const [color, setColor] = useState("any_color");
  const [orientation, setOrientation] = useState("any_rientation");
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { allImagesUpload } = useSelector((state) => state.allImagesUpload);
  const { Unsplash } = useSelector((state) => state.Unsplash);
  const [loading, setLoading] = useState(true);
  // Use useEffect to fetch default images when the component mounts
  useEffect(() => {
    setLoading(true)
    const url = `https://api.unsplash.com/photos/random?count=25&client_id=${API_KEY}`;

    axios
      .get(url)
      .then((response) => {
        setPhotos(response.data);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(true)
        console.log(error);
      });
  }, [color, orientation]);

  const dispatch = useDispatch();
  // Use useEffect to fetch new images whenever the search criteria change
  useEffect(() => {
    const fetchPhotos = async () => {
      const response = await axios.get(
        `https://api.unsplash.com/search/photos/?query=${searchTerm}&color=${color}&orientation=${orientation}&client_id=${API_KEY}`
      );
      setPhotos(response.data.results);
    };
    if (searchTerm !== "") {
      fetchPhotos();
    }
  }, [searchTerm, color, orientation]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };
  useEffect(() => {
    const updatedArray = socialAccountEditorData.map(obj => {
      return { ...obj, imageUrl: allImagesUpload };
    });
    dispatch(handleTextChange(updatedArray));
  }, [allImagesUpload])
  const handleImageSelection = (event, images) => {
    if (event.target.checked) {
      setImagesSelected([...imagesSelected, images]);
    } else {
      setImagesSelected(imagesSelected.filter((img) => img !== images));
    }
  };
  const handleCancel = () => {
    onClose()
    dispatch(handleDeleteAllImage(imagesSelected));
  };
  const handleUseMedia = (images) => {
    if (imagesSelected) {
      dispatch(handleAllUploadImage([...allImagesUpload, ...imagesSelected]));
    } else {
      dispatch(handleDeleteAllImage(imagesSelected));
    }
    onClose()
  }
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={onClose}
        sx={{ overflowY: "hidden" }}
      >
        <Box height="90vh">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            p={2}
          >
            <Typography variant="h3" fontWeight={700}>
              Select Media
            </Typography>

            <ClearOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={onClose}
              className="closeButtonAi"
            />
          </Box>
          <Divider />
          <Box display="flex" sx={{ m: 3, justifyContent: "space-evenly" }}>
            <FormControl>
              <TextField
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search Unsplash Photos"
                variant="outlined"
                inputProps={{
                  style: { fontSize: 16, padding: "16px 16px" },
                }}
              />
            </FormControl>
            {searchTerm ? (
              <Box display="flex" alignItems="center">
                <Box mr={3}>
                  <FormControl>
                    <Select
                      value={color}
                      id="selectButtonType"
                      onChange={handleColorChange}
                      variant="outlined"
                      inputProps={{
                        style: { fontSize: 16, padding: "16px 16px" },
                      }}
                    >
                      <MenuItem value="any_color">
                        <em>Any Color</em>
                      </MenuItem>
                      <MenuItem value="black_and_white">
                        Black and White
                      </MenuItem>
                      <MenuItem value="white">White</MenuItem>
                      <MenuItem value="black">Black</MenuItem>
                      <MenuItem value="yellow">Yellow</MenuItem>
                      <MenuItem value="orange">Orange</MenuItem>
                      <MenuItem value="red">Red</MenuItem>
                      <MenuItem value="purple">Purple</MenuItem>
                      <MenuItem value="magenta">Magenta</MenuItem>
                      <MenuItem value="green">Green</MenuItem>
                      <MenuItem value="teal">Teal</MenuItem>
                      <MenuItem value="blue">Blue</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <Select
                      id="selectButtonType"
                      value={orientation}
                      onChange={handleOrientationChange}
                      variant="outlined"
                    >
                      <MenuItem value="any_rientation">
                        <em>Any Orientation</em>
                      </MenuItem>
                      <MenuItem value="landscape">Landscape</MenuItem>
                      <MenuItem value="portrait">Portrait</MenuItem>
                      <MenuItem value="squarish">Square</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box sx={{ p: 2, overflowY: "auto" }} width="100%">
          {photos.length >0 ? (
            <ImageList cols={5} gap={6} variant="masonry">
              {photos.map((photo) => (
                <Box className="unsplash-image-container">
                  <ImageListItem>
                    <label className="image-checkbox-label">
                      <img
                        key={photo.id}
                        src={photo.urls.regular}
                        alt={photo.description}
                        //
                        className="unsplash_img"
                      />
                      <input
                        type="checkbox"
                        className="image-checkbox"
                        onChange={(event) =>
                          handleImageSelection(event, photo.urls.regular)
                        }
                      />
                    </label>
                  </ImageListItem>
                </Box>
              ))}
            </ImageList>
          ) : (
            <ImageList cols={5} gap={6} variant="masonry">
              <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, overflowY: "auto" }} width="100%">
                <Skeleton variant="rectangular" width="100%" height={300} />
                <Skeleton variant="text" width="70%" />
                <Skeleton variant="text" width="50%" />
                <Skeleton variant="text" width="30%" />
              </Box>
            </ImageList>
          )}
        </Box>
        <Box display="flex" sx={{ ml: "auto", p: 3 }}>
          <Button onClick={handleCancel} >Cancel</Button>
          {imagesSelected ? (
            <Button variant="contained" onClick={(images) => handleUseMedia(images)}>
              Use Media
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Dialog>
    </>
  );
}

export default UnsplashModal;
