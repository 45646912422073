import * as React from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  Typography,
  Grid
} from "@mui/material";
import EditorComp from "../../EditorComp";
import instagramlogo from "../../../../assets/images/Instagram.png";
import "../iconsBox.css";
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux"
import { handleTextChange } from "../../../../redux/Slice/textArea";

function Instagram({ socialId }) {
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { allSocialAccountData } = useSelector((state) => state.user);
  const [insta_radioValue, setInstaRadioValue] = React.useState("Post");
  const [helperText, setHelperText] = React.useState('Please include an image or video');
  const dispatch = useDispatch();
  const [inputVal, setInputVal] = React.useState()

  const handleChangeInputInsta = (e) => {
    setInstaRadioValue(e.target.value);
  };

  const handleBlur = () => {
    let selectedItemIndex = socialAccountEditorData?.findIndex((item) => item.socialAccountName === "instagram");
    let editorCurrentVal = []
    if (selectedItemIndex >= 0) {
      const updatedItem = {
        ...socialAccountEditorData[selectedItemIndex],
        postType: insta_radioValue,
        firstComment: inputVal?.firstComment,
        shopGridLink: inputVal?.shopGridLink,
        location: inputVal?.location
      };
      editorCurrentVal = [
        ...socialAccountEditorData.slice(0, selectedItemIndex),
        updatedItem,
        ...socialAccountEditorData.slice(selectedItemIndex + 1)
      ];
    }
    dispatch(handleTextChange(editorCurrentVal));
  }
  const handleChangeInputVal = (e) => {
    setInputVal({ ...inputVal, [e.target.name]: e.target.value })
  }
  return (
    <Grid className="events-box">
      <Grid display="flex" justifyContent="space-between">
        <Grid display="flex">
          <img className="sub-title-image-card" alt="" src={instagramlogo} />
          <Typography variant="header2" className="sub-title-card">{allSocialAccountData?.instagram?.pageName}</Typography>
        </Grid>
        <Grid display="flex" justifyContent="space-between">
            <FormControl >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="postType"
                value={insta_radioValue}
                onChange={handleChangeInputInsta}
                row
               
              >
                <FormControlLabel
                  value="Post"
                  defaultChecked
                  control={<Radio />}
                  label="Post"
                  sx={{fontSize:"12px",ml:"0px"}}
                  className="insta-post"

                />
                <FormControlLabel   sx={{fontSize:"12px",ml:"0px"}} value="Reel" control={<Radio />} label="Reel" />
              </RadioGroup>
            </FormControl>
        </Grid>
      </Grid>
  
      <EditorComp textAreaName={"instagram"} socialId={socialId} />

      <Box>
        {insta_radioValue === "Post" && (
          <form align="left" >
            {/* <label className="first-comment" >First comment</label>
            <br />
            <TextareaAutosize
              minRows={3}
              style={{ width: "87%" }}
              className="firstComment-textarea"
              placeholder=""
              name="firstComment"
              // onBlur={HandleBlur}
              onChange={handleChangeInputVal}
              onBlur={handleBlur}
            />
            <Box>
              <label className="shopGridLink">Shop Grid Link</label>
              <br />
              <input type="text"
                id="shopGridLinkField"
                name="shopGridLink"
                onChange={handleChangeInputVal}
                onBlur={handleBlur}
              />
            </Box>
            <Box>
              <label className="locationText">Location</label>
              <br />
              <input type="text"
                id="locationField"
                name="location"
                onChange={handleChangeInputVal}
                onBlur={handleBlur}
              />
            </Box> */}
          </form>
        )}
        {insta_radioValue === "Reel" && (
          <Box>
            {/* <Box align="left">
              <label className="locationText">Location</label>
              <br />
              <input type="text"
                id="locationField"
                name="location"
              onChange={handleChangeInputVal}
              onBlur={handleBlur}
              />
            </Box>
            <Box align="left" display="flex">
              <Typography sx={{ ml: 3.5, mt: 1 }} variant="h6">Share to Feed</Typography>
              <Switch />
            </Box> */}
          </Box>
        )}
      </Box>

    </Grid>
  );
};

export default Instagram;
