import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allImagesUpload: [],
  idImage: "",
  url: "",
  uploadVideo_url:"",
  ImageLoading:false
};

export const allImagesUploadSlice = createSlice({
  name: "allImagesUpload",
  initialState,
  reducers: {
    handleAllUploadImage: (state, action) => {
      state.allImagesUpload = action.payload;
    },

    handleDeleteAllImage: (state, action) => {
      state.allImagesUpload = state.allImagesUpload.filter(
        (image) => image !== action.payload
      );
    },
    handleUploadVideo: (state, action) => {
      state.uploadVideo_url = action.payload;
      
    },
    handleDeleteAllVideo: (state, action) => {
      state.uploadVideo_url = ""
      },
    handleImageLoading:(state,action)=>{
      state.ImageLoading= action.payload
    }
    },
  });
// Action creators are generated for each case reducer function
export const { handleAllUploadImage, handleDeleteAllImage,handleUploadVideo,handleDeleteAllVideo,handleImageLoading } =
  allImagesUploadSlice.actions;

export default allImagesUploadSlice.reducer;
