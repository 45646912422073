import { makeStyles } from '@mui/styles';

export const headerStyles = makeStyles({
    h1: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 42,
    },
    h2: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 32
    },
    h3: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 28,
        fontWeight: 400,
    },
    h4: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 22,
        fontWeight: 400,
    },
    h5: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 16,
        fontWeight: 400,
    },
    h6: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 400,
    },
    p: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 12,
        fontWeight: 400,
    },
    body1: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 10,
        fontWeight: 400,
    },
    body2: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: 8,
        fontWeight: 400,
    },
    label: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: "16px",
        lineHeight: "12px"
    },
    require: {
        color: "red"
    },
   heading: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: "28px",
        lineHeight: "1.2",
        fontWeight: "700",
        marginBottom: "48px",
        marginTop: "48px",
    },
    subheading: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontSize: "16px",
        lineHeight: "1.2",
        fontWeight: "100",
        marginBottom: "32px",
    },
    footerHeading:{
        fontFamily: 'Rubik,sans-serif',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: "1.6",
        marginTop: '32px',
        marginBottom:"32px",
        textAlign: 'center',
    },
    terms_privacy: {
        fontSize: '12px',
        fontFamily: 'Rubik',
      },
    terms: {
        color: 'blue',
        fontSize: '12px',
        cursor: 'pointer'
      },
   eventCard_title:{
    fontFamily: 'Rubik,sans-serif',
    fontSize: "16px",
    fontWeight: '600',
    marginLeft: "8px"
   }

});


