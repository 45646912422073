import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageUpload: [],
  imageLimit:false
};

export const imageUploadSlice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    handleUploadImage: (state, action) => {
      state.imageUpload = action.payload;
    },
    handleImageLimit: (state, action) => {
      state.imageLimit = action.payload;
      console.log('state',state);
      console.log('payload',action.payload);
    },
    handleDeleteUploadImage: (state, action) => {
      state.imageUpload = state.imageUpload.filter(
        (image) => image !== action.payload
      );
    },
  },
});
// Action creators are generated for each case reducer function
export const { handleUploadImage,handleDeleteUploadImage ,handleImageLimit} =
imageUploadSlice.actions;

export default imageUploadSlice.reducer;
