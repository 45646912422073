import React, { useState } from "react";
import moment from "moment";
import { Avatar, Box, Paper, Tooltip, Typography, Button, IconButton } from "@mui/material";
import EventCard from "../../../components/EventCard";
import "./index.css";
import AddIcon from "@mui/icons-material/Add";
import ScheduleDialog from "../../../components/ScheduleDialog";
import {
  LinkedinIcon,
  YoutubeIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  FacebookIcon,
} from '../../../assets/assets/assets';
import { useSelector, useDispatch } from "react-redux";
import { selectCalender } from "../../../redux/Slice/calender";
import { handleOpenScheduleDialog } from "../../../redux/Slice/scheduleDialog";
import { handleSelectDate } from "../../../redux/Slice/selectDate";
import { handleUploadVideo } from "../../../redux/Slice/allImagesUpload";
import * as api from "../../../redux/Api";
import { setCookie, getCookie, removeCookie } from '../../../helpers/cookie';
import {getTimezone} from "../../../helpers/timeZone";
import {getBrandSettingData} from "../../../redux/reduxThunk";
import NestedAvatar from "../../../components/common/NestedAvatar"
function MonthComponent({ date, events }) {

  const calender = useSelector(selectCalender);
  const dispatch = useDispatch()
  const weeks = [];
  // Get the current date
  const currentDate = new Date();
  const { openScheduleDialog } = useSelector((state) => state.openScheduleDialog);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { brandSettingData } = useSelector((state) => state.user);
  const startOfMonth = moment(date).startOf("month");
  const endOfMonth = moment(date).endOf("month");
  const startOfWeek = startOfMonth.clone().startOf("week");
  const endOfWeek = endOfMonth.clone().endOf("week");
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState()
  const createdBrandId =getCookie('created_brand_id')
  let day = startOfWeek;
  let weekIndex = 0;
  while (day <= endOfWeek) {
    weeks[weekIndex] = weeks[weekIndex] || [];
    weeks[weekIndex].push(day);
    day = day.clone().add(1, "d");
    if (day.day() === 0) {
      weekIndex += 1;
    }
  }
  // Function to check if a date is in the past
  const isPastDate = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date < yesterday;
  };
 
  const handleClickOpen = (day) => {
    if (!('timeZone' in brandSettingData.data.settings)) {
      const timeZone =getTimezone()
      let updatedObj = {
        "timeZone": timeZone,
        "brandId":createdBrandId
  
    }; 
      api.updateSettings(updatedObj).then((res)=>{
        if(res.status ===200){
          dispatch(getBrandSettingData(createdBrandId));
        }
      })
    }
    dispatch(handleSelectDate(moment(day)))
    dispatch(handleOpenScheduleDialog(true));
    dispatch(handleUploadVideo(""))
 
  }

  const handleTooltipOpen = (event) => {
    setCurrentEvent(event);
    if (openScheduleDialog) {
      setIsTooltipOpen(false);
      setIsEditOpen(false)
    } else {
      setIsTooltipOpen(true);
      setIsEditOpen(false);
    }

  };
  // const filterData= events.filter((item)=>item.socialAccountName === )
  return (
    // <Paper width="100%" height="100%">
    <div className="month-calender" >
      <table className="month-table">
        <thead className="month-thead">
          <tr className="t-head">
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, index) => (
            <tr key={index}>
              {week.map((day) => {
                const eventsForDay = events.filter((event) =>
                  moment(event.publishDateTime).isSame(day, "day")
                );
                return (
                  <td
                    key={day.format()}
                    id={`${isPastDate(day) ? "disabled" : ""}`}
                    className="month-td"
                  >
                    <Box className="date-month">
                      <Box className="date">{day.format("D")}</Box>
                      <Box className="days_month">
                        <Box className="overlay" >
                          <Button
                           title="Add post"
                            className={calender.weekView ? "addIcon-week" : "addIcon-month"}
                            variant="outlined"
                            onClick={() => handleClickOpen(day)}>
                            <AddIcon />
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    {eventsForDay.map((event) => (
                      <Tooltip title={<EventCard event={event} setIsEditOpen={setIsEditOpen} />}
                        open={isTooltipOpen && !isEditOpen && currentEvent === event}
                        // open={true}
                        onClick={() => handleTooltipOpen(event)}
                        onClose={() => setIsTooltipOpen(false)}
                      >
                        <Box
                          key={event.title}
                          display=" flex"
                          // width="121px"
                          // height="50px"
                          justifyContent="space-around"
                          alignItems="center"
                          backgroundColor="antiquewhite"
                          p="8px 16px 8px 16px"
                          sx={{ borderRadius: "5px", m: 1,borderLeft:"2px solid" }}
                          onMouseEnter={() => handleTooltipOpen(event)}
                        >
                           <NestedAvatar
                      // key={index}
                      sx={{ height: "32px !important", width: "32px important" }}
                      src1={event?.profileImage}
                      src2={event?.socialImage}

                    />
                          {/* <Avatar
                            src={event?.profileImage}
                            sx={{ width: "40px", height: "40px" }}
                          /> */}
                          <Typography sx={{fontSize:"14px"}}>{moment(event?.publishDateTime).format('h:mm a')} </Typography>

                          {/* <img
                            src={event?.socialImage}
                            width="9.9px"
                            height="9.9px"
                            background-color="#fff"
                            alt=""
                          /> */}
                        </Box>
                      </Tooltip>
                    ))}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {openScheduleDialog && <ScheduleDialog event={null} />}

    </div>
  );
}

export default MonthComponent;
