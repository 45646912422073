import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openScheduleDialog: false,
  isEditScheduleDialog:false
};

export const openScheduleDialogSlice = createSlice({
  name: "openScheduleDialog",
  initialState,
  reducers: {
    handleOpenScheduleDialog: (state, action) => {
      state.openScheduleDialog = action.payload;
    },
    handleIsEditScheduleDialog: (state, action) => {
      state.isEditScheduleDialog = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {handleOpenScheduleDialog,handleIsEditScheduleDialog } =
openScheduleDialogSlice.actions;

export default openScheduleDialogSlice.reducer;
