import {
  Box,
  Dialog,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  Grid,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import { Search } from "@mui/icons-material";
import SearchComp from "../../components/common/SearchComponent/SearchComp";
import { useTheme } from "@mui/material/styles";
// import MediaLibrary from './MediaLibrary'
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { handleLibraryImage } from "../../redux/Slice/mediaLibrary";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import {
  handleAllUploadImage,
  handleDeleteAllImage,
  handleUploadVideo
} from "../../redux/Slice/allImagesUpload";
import SocialMediaImageUpload from "../../components/common/ImageUpload/SocilMediaImageUpload";
import * as api from "../../redux/Api";
import { handleDeleteUploadImage } from "../../redux/Slice/imageUpload";
import { handleOpenScheduleDialog } from "../../redux/Slice/scheduleDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getMediaLibraryData } from "../../redux/reduxThunk";
import ScheduleDialog from "../../components/ScheduleDialog";
import index from ".";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { borderRadius } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import { buttonStyles } from "../../Themes/buttonTheme"
import {headerStyles} from "../../Themes/headerTheme";
import {inputStyles} from "../../Themes/InputTheme";
import {
  InputAdornment,
  createStyles,
  makeStyles
} from "@mui/material";
const maxWidth = "xl";


function SideBarLibrary({ title, onClose }) {
  const dispatch = useDispatch();
  const buttonClass = buttonStyles();
  const headerClass=headerStyles();
  const inputClass=inputStyles()
  const [type, setType] = React.useState("all_media");
  const [category, setCategory] = React.useState({ "filterByType": "", "filterByUsed": "", "search": "" });
  const [imagesSelected, setImagesSelected] = React.useState(false);
  const { allMediaLibraryData } = useSelector((state) => state.libraryImage);
  const { imageUpload } = useSelector((state) => state.imageUpload);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [openSelectedImage, setOpenSelectedImage] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [openLabelsList, setOpenLabelsList] = React.useState(false);
  const [text, setText] = React.useState("");
  const [addedText, setAddedText] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState();
  const [editedData, setEditedData] = React.useState();
  const [error, setError] = React.useState("")
  const { openScheduleDialog } = useSelector(
    (state) => state.openScheduleDialog
  );

  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [deletedId, setDeleteId] = React.useState([])
  const [isEditing, setIsEditing] = React.useState(false);
  const [editInputVal, setEditInputVal] = React.useState()
  const { uploadVideo_url, ImageLoading } = useSelector((state) => state.allImagesUpload);
  const [manageLabels, setManageLabels] = React.useState([
    {
      id: 1,
      labelName: "ANB",
      addlogo: <AddCircleOutlineIcon />,
      editlogo: <EditIcon />,
      deletelogo: <DeleteIcon />,
    },
    {
      id: 2,
      labelName: "Birthday",
      addlogo: <AddCircleOutlineIcon />,
      editlogo: <EditIcon />,
      deletelogo: <DeleteIcon />,
    },
    {
      id: 3,
      labelName: "GK FS",
      addlogo: <AddCircleOutlineIcon />,
      editlogo: <EditIcon />,
      deletelogo: <DeleteIcon />,
    },
    {
      id: 4,
      labelName: "SECOND",
      addlogo: <AddCircleOutlineIcon />,
      editlogo: <EditIcon />,
      deletelogo: <DeleteIcon />,
    },
  ]);
  const handleAddTextLabels = (lablename, addlogo) => {
    setAddedText([...addedText, lablename]);
    // setManageLabels((labels) =>
    //   labels.filter((label) => label.addlogo !== addlogo)
    // );

    setOpenLabelsList(true);
  };

  const handleDeleteLabel = (labelName) => {
    setManageLabels((labels) =>
      labels.filter((label) => label.labelName !== labelName)
    );
  };

  const handleCancelEdit = () => {
    setSelectedLabel("");
  };

  const handleEditLabel = (labelName) => {
    setSelectedLabel(labelName);
    setIsEditing(true);
    setEditedData(labelName);
  };

  const handleSaveLabel = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      setSelectedLabel(null);
    }
  };

  const handleUpdatedLabel = (event, index) => {
    event.stopPropagation();
    const updatedLabels = [...manageLabels];
    updatedLabels[index].labelName = editedData;
    setManageLabels([...updatedLabels]);
  };
  const handleChangeFilter = (e) => {
    if (e.target.value === "all") {
      setCategory({ ...category, [e.target.name]: "" });
    } else {
      setCategory({ ...category, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    const data = { "filterByType": category.filterByType, "filterByUsed": category.filterByUsed, "search": "" }
    dispatch(getMediaLibraryData(data));
  }, [category])


  const handleImageSelection = (images) => {
    setSelectedImage(images);
    setOpenSelectedImage(true);
    // dispatch(handleAllUploadImage([images]));
  };
  const handleImageChecked = (event, images) => {
    setOpenSelectedImage(false);
    setSelectedImage(images);
    setDeleteId([...deletedId, images._id])
    if (event.target.checked) {
      setImagesSelected(true);
    } else {
      setImagesSelected(false);
    }
  };
  const handleCloseSelectedImage = () => {
    setOpenSelectedImage(false);
  };
  const handleDeleteLibraryImages = async () => {
    await api.deleteMediaLibrary(deletedId).then((res) => {
      if (res.status === 200) {
        const data = { "filterByType": "", "filterByUsed": "", "search": "" }
        dispatch(getMediaLibraryData(data));
      }
    });
  };

  const handleUseMediaLibrary = () => {
    let displayImage;
    if (selectedImage.mediaType === "video") {
      displayImage = selectedImage;
      dispatch(handleUploadVideo(displayImage));
    } else {
      displayImage = selectedImage?.mediaUrl;
      dispatch(handleAllUploadImage([displayImage]));
    }
    dispatch(handleOpenScheduleDialog(true));
  };
  const handleEditor = () => {
    window.location.href = `${api.editorUrl}`;
  };
  const handleAddText = () => {
    setAddedText([...addedText, text]);
    setText("");
  };

  const handleDeleteText = (index) => {
    const updatedTexts = [...addedText];
    updatedTexts.splice(index, 1);
    setAddedText(updatedTexts);
  };

  const handleOpenLabelsList = () => {
    setOpenLabelsList(true);
  };
  const handleCloseLabelsList = () => {
    setOpenLabelsList(false);
  };
  const handleChangeEditInput = (e) => {
    setEditInputVal({ ...editInputVal, [e.target.name]: e.target.value })
  }
  const handleEditSave = async () => {
    await api.updateMediaLibrary({
      "_id": selectedImage?._id,
      "adminId": selectedImage?.adminId,
      "mediaType": selectedImage?.mediaType,
      "mediaUrl": selectedImage?.mediaUrl,
      "mediaName": editInputVal.mediaName,
      "mediaDescription": editInputVal.mediaDescription,
      "mediaTags": addedText,
      "mediaCategory": "",
    }).then((res) => {
      if (res.status === 200) {
        setOpenSelectedImage(false);
        const data = { "filterByType": "", "filterByUsed": "", "search": "" }
        dispatch(getMediaLibraryData(data))
      } else {
        setOpenSelectedImage(true);
      }
    })
  }
  return (
    <>
      <Box
        display="flex"
        component="form"
        sx={{ mt: 2, width: "fit-content", justifyContent: "space-between",mt:'100px', ml:'245px' }}
      >

        <Box display="flex" sx={{ ml: 4 }}>
          <Select
            name="filterByType"
            labelId="label"
            defaultValue="all"
            align="left"
            sx={{ height: "44px", width: "150px",background:'white' }}
            onChange={handleChangeFilter}
          >
            <MenuItem value="all">
              <Typography variant="h5">
                Filter by Type
              </Typography>

            </MenuItem>
            <MenuItem value="image">
              <Typography variant="h5">
                Photo
              </Typography>
            </MenuItem>
            <MenuItem value="video">
              <Typography variant="h5">
                Video
              </Typography>
            </MenuItem>
          </Select>
        </Box>

        <Box display="flex" sx={{ ml: 4 }}>
          <Select
            name="filterByUsed"
            labelId="label"
            defaultValue="all"
            align="left"
            sx={{ height: "44px", width: "150px",background:'white' }}
            onChange={handleChangeFilter}
          >
            <MenuItem value="all">
              <Typography variant="h5">
                Filter by
              </Typography>

            </MenuItem>
            <MenuItem value="used">
              <Typography variant="h5">
                Used
              </Typography>
            </MenuItem>
            <MenuItem value="unused">
              <Typography variant="h5">
                Unused
              </Typography>
            </MenuItem>
          </Select>
        </Box>

      </Box>

      <Box sx={{ mt:"14px",pl:"14px", pl:'250px'}} width="100%">
        <Grid container>
          <Grid xs={6} sm={4} md={3} lg={2}>
            <SocialMediaImageUpload
              setError={setError}
              type={"unused"}
              linkValue={
                <Box className="add-upload-images-library" sx={{ mt: 0.5 }}>
                  <BackupOutlinedIcon sx={{ fontSize: "129px", color: "gray" }} />
                </Box>
              }
            />
          </Grid>
          <Grid xs={6} sm={8} md={9} lg={9}>
            <ImageList

              cols={5}
              // gap={4}
              variant="masonry"
            >
              {ImageLoading ? <CircularProgress /> :
                <>
                  {allMediaLibraryData && allMediaLibraryData?.map((item, _id) => {
                    return (
                      <>
                        <ImageListItem>
                          <Box>
                            <label className="image-checkbox-label-library">
                              {item.mediaType === "image" ?
                                <img
                                  className="previewImgLibrary"
                                  src={item.mediaUrl}
                                  alt="img"
                                  onClick={() =>
                                    handleImageSelection(item)
                                  }
                                />
                                :
                                <video
                                  className="videoPreview"
                                  controls
                                  src={item.mediaUrl}
                                  onClick={() =>
                                    handleImageSelection(item)
                                  }
                                ></video>
                              }

                            </label>
                            <input
                              type="checkbox"
                              className="image-checkbox-library"
                              onChange={(event) =>
                                handleImageChecked(event, item)
                              }
                            />
                          </Box>
                        </ImageListItem>

                      </>);
                  })}
                </>
              }
            </ImageList>
          </Grid>
        </Grid>

        <Box>
          {openSelectedImage && (
            <Dialog
              maxWidth={maxWidth}
              open={openSelectedImage}
              fullWidth={fullWidth}
              onClose={handleCloseSelectedImage}
              sx={{ borderRadius: '16px' }}
            >
              <DialogContent display="flex">
                <Grid xs={12} md={12} lg={12}>
                  <Box display="flex">
                    <Grid xs={6} md={6} lg={6}>
                      <ImageListItem>
                        {selectedImage.mediaType === "image" ?
                          <img
                            src={selectedImage?.mediaUrl}
                            alt="images"
                            id="uploadedImages"
                            align="left"
                            style={{
                              width: "100%",
                              height: "500px",
                              borderRadius: '8px'
                              // objectFit: "fill",
                            }}
                          />

                          :
                          <video
                            alt="images"
                            id="uploadedImages"
                            align="left"
                            style={{
                              width: "100%",
                              height: "500px",
                              borderRadius: '8px'
                              // objectFit: "fill",
                            }}
                            controls
                            src={selectedImage?.mediaUrl}

                          ></video>
                        }

                      </ImageListItem>
                    </Grid>
                    <Grid xs={6} md={6} lg={6} sx={{ ml: 2 }}>
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <Typography className={headerClass.label}  sx={{ color: "gray" }} variant="h5">
                          Name
                        </Typography>
                        <input
                          type="text"
                          placeholder="Add a name"
                          className={inputClass.input}
                          defaultValue={selectedImage?.mediaName}
                          name="mediaName"
                          onChange={handleChangeEditInput}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}  >
                        <Typography className={headerClass.label} variant="h5" >
                          Labels
                        </Typography>
                        <Box className="input-container">
                          <input
                            type="text"
                            placeholder="Label"
                            value={text}
                            className={inputClass.input}
                            onChange={(e) => setText(e.target.value)}
                          />
                          {/* <AddCircleOutlineIcon
                            onClick={handleAddText}
                            className="add-icon-input"
                          /> */}
                        </Box>
                        <div style={{ display: "flex", flexWrap: "wrap", mb: 3 }}>
                          {addedText.map((text, index) => (
                            <Box>
                              {text && (
                                <ListItem key={text}>
                                  <Box>{text}</Box>
                                  <IconButton>
                                    <ClearIcon
                                      onClick={() => handleDeleteText(index)}
                                    />
                                  </IconButton>
                                </ListItem>
                              )}
                            </Box>
                          ))}
                        </div>
                      </Box>
                      <Box>
                        {/* <Typography
                          variant="h5"
                          sx={{
                            color: "gray",
                            mb: 3,
                            mt: 3,
                            cursor: "pointer",
                          }}
                          onClick={handleOpenLabelsList}
                        >
                          Manage Labels
                        </Typography> */}
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography className={headerClass.label}  variant="h5">
                          Caption
                        </Typography>
                        <input
                          autoFocus
                          className={inputClass.input}
                          name="mediaDescription"
                          placeholder="Caption"
                          defaultValue={selectedImage?.mediaDescription}
                          onChange={handleChangeEditInput}
                        ></input>
                      </Box>
                      <Box display="flex" justifyContent="end" sx={{ mt: 8 }}>

                        <Box display="flex" align="right">
                          <Button className={buttonClass.Grey_small_Btn} sx={{ mr: 2 ,pt:"11px"}} onClick={handleCloseSelectedImage}>
                            Cancel
                          </Button>
                          <Button className={buttonClass.Grey_small_Btn} sx={{ mr: 2,pt:"11px" }} onClick={handleEditSave}>Save</Button>
                          <Button
                            className={buttonClass.Medium_sized_Btn}
                            onClick={(images) => handleUseMediaLibrary(images)}
                          >
                            Use Media
                          </Button>

                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>
          )}
        </Box>
        <Box>
          <Dialog
            maxWidth="sm"
            open={openLabelsList}
            fullWidth={fullWidth}
            onClose={handleCloseLabelsList}
          >
            <Box display="flex" sx={{ m: 2 }}>
              <Typography variant="h4" sx={{ mr: "auto" }}>
                Manage Labels
              </Typography>
              <ClearOutlinedIcon
                onClick={handleCloseLabelsList}
                sx={{ cursor: "pointer", mr: 2, ml: "auto" }}
                className="closeButtonAi"
              />
            </Box>
            <Divider />
            <Box className="search-labels-container">
              <SearchIcon className="seachIcon-labels" />
              <input
                type="text"
                placeholder="Search labels"
                className="textfield-searchLabels"
              />

              <Box>
                {manageLabels.map((item, index) => (
                  <>
                    <div key={item.labelName} className="edit-labels-container">
                      {isEditing && selectedLabel === item.labelName ? (
                        <Box>
                          <input
                            className="edit-Labels"
                            defaultValue={editedData}
                            onKeyDown={handleSaveLabel}
                            onChange={(event) =>
                              setEditedData(event.target.value)
                            }
                          />
                          <Box className="edit-Labels-logos">
                            <CheckCircleOutlineRoundedIcon
                              onClick={(event) =>
                                handleUpdatedLabel(event, index)
                              }
                            />
                            <HighlightOffRoundedIcon
                              onClick={handleCancelEdit}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          sx={{
                            ml: 3,
                            mr: 2,
                            mt: 2,
                            mb: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              backgroundColor: "rgba(0,65,125,.12)",
                              padding: "5px",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            {item.labelName}
                          </Typography>
                          <Box display="flex">
                            {!addedText.includes(item.labelName) && <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAddTextLabels(
                                  item.labelName,
                                  item.id,
                                  item.addlogo
                                )
                              }
                            >
                              {item.addlogo}
                            </Box>}
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleEditLabel(item.labelName)}
                            >
                              {item.editlogo}
                            </Box>
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleDeleteLabel(item.labelName)}
                            >
                              {item.deletelogo}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </div>
                  </>
                ))}
              </Box>
              <Button onClick={handleCloseLabelsList}>Go Back</Button>
            </Box>
          </Dialog>
        </Box>
        <Box>
          {imagesSelected ? (
            <Box  display="flex" sx={{ ml: "20px",mb:'50px'}}>
              <Button sx={{mr: "20px" }} className={buttonClass.Red_color_Btn} onClick={() => handleDeleteLibraryImages()}>
                Delete
              </Button>
              {/* <Button>Add labels</Button> */}
              {/* <Box display="flex" alignItems="center" mr={3}>
                <Button variant="h6">
                  <StarOutlineOutlinedIcon sx={{ mr: 1 }} />
                  Favorites
                </Button>
              </Box> */}
              <Button
              className={buttonClass.Blue_color_Btn}
                variant="contained"
                onClick={(images) => handleUseMediaLibrary(images)}
              >
                Use Media
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Typography sx={{ color: "red", marginLeft: "22px", fontSize: "14px" }}>{error}</Typography>
      </Box >
      {openScheduleDialog ? <ScheduleDialog event={null} /> : null
      }
    </>
  );
}

export default SideBarLibrary;
