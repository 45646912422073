import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  draftDay:"",
  updateDate: (moment())
};

export const selectDateSlice = createSlice({
  name: "selectDate",
  initialState,
  reducers: {
      handleSelectDate: (state, action) => {
          state.draftDay = action.payload;
        },
        handleUpdateDate: (state, action) => {
          state.updateDate = action.payload;
         
        }  
    }
});
// Action creators are generated for each case reducer function
export const { handleSelectDate, handleUpdateDate } =
selectDateSlice.actions;

export default selectDateSlice.reducer;
