import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentUpload: [],
};

export const documentUploadSlice = createSlice({
  name: "documentUpload",
  initialState,
  reducers: {
    handleUpdateDocument: (state, action) => {
      state.documentUpload = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { handleUpdateDocument } =
documentUploadSlice.actions;

export default documentUploadSlice.reducer;
