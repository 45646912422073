import * as React from "react";
import { Box, Divider, Typography,Grid } from "@mui/material";
import EditorComp from "../../EditorComp";
import facebooklogo from "../../../../assets/images/Facebook.png";
import "../iconsBox.css";
import { useForm } from 'react-hook-form';
import {useSelector} from "react-redux"

function FacebookPage({socialId}) {
  const { allSocialAccountData } = useSelector((state) => state.user);
  
  return (
    <Grid className="events-box">
      <Box display="flex">
        <img className="sub-title-image-card" alt="" src={facebooklogo} />
        <Typography variant="header2" className="sub-title-card">{allSocialAccountData?.facebookPage?.pageName}</Typography>
      </Box>
      {/* <Divider /> */}
        <EditorComp textAreaName={"facebookPage"} />
    </Grid>
  );
};

export default FacebookPage;
