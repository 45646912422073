import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import SearchComp from "../../components/common/SearchComponent/SearchComp";
import { useTheme } from "@mui/material/styles";
// import MediaLibrary from './MediaLibrary'
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import "./mediaLibrary.css";
import imageUpload, { handleUpdateImage } from "../../redux/Slice/imageUpload";
import { useDispatch, useSelector } from "react-redux";
import { handleLibraryImage } from "../../redux/Slice/mediaLibrary";
import {
  handleAllUploadImage,
  handleDeleteAllImage,
  handleUploadVideo
} from "../../redux/Slice/allImagesUpload";
import { handleTextChange } from "../../redux/Slice/textArea";
const maxWidth = "lg";
const imgList = [
  {
    category: "photo",
    data: [
      {
        id: 1,
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab86db279767f49b13fc.jpg",
      },
      {
        id: 2,
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab86db279767f49b13fc.jpg",
      },
      {
        id: 3,
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab83db279768169b15cc.jpg",
      },
      {
        id: 4,
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab83db279768169b15cc.jpg",
      },
    ],
  },
  {
    category: "gif",
    data: [
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab8cdb279767cc9b1481.jpg",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab8cdb279767cc9b1481.jpg",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_64096676db279767b89b0d1c.png",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_64096676db279767b89b0d1c.png",
      },
    ],
  },
  {
    category: "video",
    data: [
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab71db279768799b148f.png ",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_63fe2b06db279704e8514a5f.jpg",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_63fe2b06db279704e8514a5f.jpg",
      },
      {
        preview:
          "https://cdn.publer.io/uploads/photos/thumb_6409ab71db279768799b148f.png",
      },
    ],
  },

];
function MediaLibraryMoadal({ open, onClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [type, setType] = React.useState("all_media");
  const [category, setCategory] = React.useState("all_media");
  const [imagesSelected, setImagesSelected] = React.useState([]);
  const { allImagesUpload } = useSelector((state) => state.allImagesUpload);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { allMediaLibraryData } = useSelector((state) => state.libraryImage);
  const [error, setError] = React.useState("")
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  useEffect(() => {
    const updatedArray = socialAccountEditorData.map(obj => {
      return { ...obj, imageUrl: allImagesUpload };
    });
    dispatch(handleTextChange(updatedArray));
  }, [allImagesUpload])
  const handleImageSelection = (event, images) => {

    if (event.target.checked) {
      setImagesSelected([...imagesSelected, images]);
    } else {
      setImagesSelected("");
      setError("")
    }
  };
  const handleCancel = () => {
    onClose()
    dispatch(handleDeleteAllImage(imagesSelected));
  };
  const handleUseMedia = () => {

    const isVideo = imagesSelected.some((item) => item.split('.').pop() === 'mp4');
    const isImage = imagesSelected.some((item) => item.split('.').pop() !== 'mp4');

    if (isVideo && imagesSelected.length > 1) {
      setError('Please select only one video');
    } else if (isVideo) {
      dispatch(handleUploadVideo(imagesSelected[0]));
      onClose();
    } else if (isImage) {
      dispatch(handleAllUploadImage(imagesSelected));
      onClose();
    } else {
      setError('Please select an image or video');
    }
  }
return (
  <>
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={onClose}
      sx={{ overflowY: "hidden" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3" fontWeight={700}>
            Media Library
          </Typography>
        </DialogTitle>
        <ClearOutlinedIcon
          onClick={onClose}
          sx={{ cursor: "pointer", mr: 2 }}
          className="closeButtonAi"
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        noValidate
        component="form"
        sx={{ m: 2, width: "fit-content", justifyContent: "space-between" }}
      >
        <SearchComp />

        <Box display="flex" sx={{ ml: 4 }}>
          <Select
            labelId="label"
            id="selectButtonType"
            value={type}
            align="left"
            onChange={handleChangeType}
          >
            <MenuItem value="all_media">Filter by Type</MenuItem>
            <MenuItem value="photo">Photo</MenuItem>
            <MenuItem value="gif">Gif</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </Box>

        <Box display="flex" sx={{ ml: 4 }}>
          <Select
            labelId="label"
            id="selectButtonType"
            value={category}
            align="left"
            onChange={handleChangeCategory}
          >
            <MenuItem value="all_media">Filter by </MenuItem>
            <MenuItem value="used">Used</MenuItem>
            <MenuItem value="unused">Unused</MenuItem>
          </Select>
        </Box>
      </Box>

      <Box display="flex" sx={{ ml: 4, mb: 2 }}>
        <Box display="flex" alignItems="center" mr={3}>
          <StarOutlineOutlinedIcon />
          <Typography variant="h5">Favorites</Typography>
        </Box>
        <Box>
          <Button variant="contained">Birthday</Button>
        </Box>
      </Box>

      <Box sx={{ p: 2, overflowY: "auto" }} width="100%">
        <ImageList
          cols={5}
          gap={4}
          variant="masonry"
          sx={{ overflowY: "initial" }}
        >
          {allMediaLibraryData && allMediaLibraryData?.map((item, _id) => {
            return (
              <>
                <div>
                  <>
                    <Box>
                      <ImageListItem>
                        <Box>
                          <label className="image-checkbox-label-library">
                            {item.mediaType === "image" ?
                              <img
                                className="previewImgLibrary"
                                src={item.mediaUrl}
                                alt="img"
                              // onClick={() =>
                              //   handleImageSelection(item)
                              // }
                              />
                              :
                              <video
                                className="previewImgLibrary"
                                height="200px"
                                width="250px"
                                style={{ marginLeft: "10px" }}
                                controls
                                src={item.mediaUrl}
                              // onClick={() =>
                              //   handleImageSelection(item)
                              // }
                              ></video>
                            }

                          </label>
                          <input
                            type="checkbox"
                            className="image-checkbox-library"
                            onChange={(event) =>
                              handleImageSelection(event, item.mediaUrl)
                            }
                          />
                        </Box>
                      </ImageListItem>
                    </Box>
                  </>

                </div>
              </>
            );
          })}
        </ImageList>
        {error && <p className="err_msg">{error}</p>}
      </Box>
     
      <Box display="flex" sx={{ ml: "auto", p: 3 }}>
     
        <Button onClick={(event) => handleCancel(event)} >Cancel</Button>
        {/* {imagesSelected && imagesSelected.length>0  ? ( */}
          <Button variant="contained" onClick={() => handleUseMedia()}>
            Use Media
          </Button>
        {/* ) : (
          ""
        )} */}
      </Box>
      {/* <MediaLibrary /> */}
    </Dialog>
  </>
);
}

export default MediaLibraryMoadal;
