import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  ImageList,
  ImageListItem,
  Input,
  Typography,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import chatIcon from "../../../assets/images/chat.png";
import ShareIcon from "../../../assets/images/send.png";
import greetKaroLogo from "../../../assets/images/Greetkaro.png";
import instagramlogo from "../../../assets/images/Instagram.png";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useSelector } from "react-redux";
import previewimage from "../../../assets/images/image.png";
import { Link } from "react-router-dom";
import Face from "@mui/icons-material/Face";

export default function InstagramPreview() {
  const { allSocialAccountData } = useSelector((state) => state.user);
  const { textAreaValue } = useSelector((state) => state.textAreaval);
  const { Gifs } = useSelector((state) => state.inputEditor);
  const { allImagesUpload } = useSelector((state) => state.allImagesUpload);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { uploadVideo_url } = useSelector((state) => state.allImagesUpload);
  const [activeIndex, setActiveIndex] = React.useState(0);

  
  return (
    <Box>
      <Card sx={{ m: 4, variant: "outlined" }}>
        <Box display="flex" className="preview-box">
          <Box display="flex">
            <Avatar
              size="sm"
              src={allSocialAccountData?.instagram?.profileImage}
              sx={{
                marginRight: "10px",
                border: "1px solid",
                width: "43px",
                height: "42px",
                borderColor: "background.body",
              }}
            />
            <Typography variant="header2" sx={{margin: "10px 0px 0px 0px"}}>{allSocialAccountData?.instagram?.pageName}</Typography>
          </Box>
          <img className="sub-title-image" src={instagramlogo} alt=""></img>
        </Box>
        <Box align="center">
          <ImageList
            cols={allImagesUpload.length >= 1 ? 1 : 0}
            variant="masonry"
          >
            {uploadVideo_url && <video height="100%" width="100%" controls src={uploadVideo_url}></video>}
            <ImageListItem>
              {allImagesUpload.length > 0 ? (
                
                <img src={allImagesUpload[0]} alt="imges" align="left" style={{width:"100%", height:"400px",objectFit: "cover"}}  />
              ) : (
                ""
              )}
            </ImageListItem>
            {Gifs && (
              <div>
                <img src={Gifs} alt="gif" width="100%" />
              </div>
            )}
          </ImageList>
        </Box>

        <CardActions disableSpacing >
          <IconButton aria-label="add to favorites" color="neutral" size="sm">
            <FavoriteBorderOutlinedIcon />
          </IconButton>
          <IconButton>
            <img
              className="sub-title-image-chat"
              src={chatIcon}
              color="neutral"
              size="sm"
              alt=""
            />
          </IconButton>
          <IconButton>
            <img
              className="sub-title-image-sent"
              src={ShareIcon}
              color="neutral"
              size="sm"
              alt=""
            />
          </IconButton>
          <Box
            sx={{ display: "flex",  gap: 0.5, ml: "25%" }}
          >
            {allImagesUpload.length > 1 &&
              allImagesUpload?.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    borderRadius: "50%",
                    width: `max(${6 - index}px, 3px)`,
                    height: `max(${6 - index}px, 3px)`,
                    bgcolor: index === 0 ? "blue" : "lightblue",
                  }}
                />
              ))}
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row-reverse", ml: "auto" }}
          >
            <IconButton>
              <BookmarkBorderOutlinedIcon />
            </IconButton>
          </Box>
        </CardActions>
        {socialAccountEditorData && socialAccountEditorData?.filter((item)=>item.socialAccountName ==="instagram")
        .map((item) => (
        <CardContent>
       
            <Typography
              variant="h6"
              className="preview-card-text"
              key={item.id}
            >
              {item.message}
            </Typography>
        </CardContent>
          ))}
         
      </Card>
    </Box>
  );
}
