import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, Grid } from "@mui/material";
import { theme } from "./theme";
import { useForm } from "react-hook-form";
import Calender from "./pages/calender";
import SideBarLibrary from "./pages/SideBarLibrary/index";
import CircularProgress from '@mui/material/CircularProgress';


function App() {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed
  }, []);
  if (isLoading) {
    return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress color="secondary" value={50} size={50} />;
    </Grid>

  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes sx={{ background: " #f5f5f5" }}>
          <Route exact path="/calendar" register={register} element={<Calender />} />
          <Route exact path="/medialibrary" element={<SideBarLibrary />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
