import { createSlice } from "@reduxjs/toolkit";
import { getPostsData } from "../reduxThunk";
const initialState = {
  postDataLoading:false,
  postDataError :"",
  postData: []
  
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  
  extraReducers: (builder) => {
    builder.addCase(getPostsData.pending, (state, action) => {
      state.postDataLoading = true;
    });
    builder.addCase(getPostsData.fulfilled, (state, action) => {
      state.postDataLoading = false;
      state.postData = action.payload.data;
    });
    builder.addCase(getPostsData.rejected, (state, action) => {
      state.postDataLoading = false;
      state.postDataError = 'error data';
      state.postData = [];
    });
  },
});



export default postSlice.reducer;
