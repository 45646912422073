import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  TextareaAutosize,
  Typography,
  Grid,
  IconButton
} from "@mui/material";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import BoltIcon from "@mui/icons-material/Bolt";
import TimelineIcon from "@mui/icons-material/Timeline";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import SearchComp from "../../components/common/SearchComponent/SearchComp";
import axios from "axios";
import * as api from "../../redux/Api";
import { useDispatch, useSelector } from "react-redux";
import { handleTextChange } from "../../redux/Slice/textArea";
import CircularProgress from '@mui/material/CircularProgress';
import HashTagGeneratorDialog from "./HashTagGeneratorDialog";
import CloseIcon from '@mui/icons-material/Close';
import { buttonStyles } from '../../Themes/buttonTheme';
function HashtagandAi() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAi, setOpenAi] = React.useState(false);
  const [aiTextData, setAiTextData] = React.useState([])
  const [Loader, setLoader] = React.useState(false);
  const [aiData, setAiData] = React.useState(false);
  const [addButton, setAddButton] = React.useState(false);
  const [hashtagDialog, setHashtagDialog] = React.useState(false);
  const [hashtags, setHashtags] = React.useState("");
  const [postData, setPostData] = React.useState()
  const open = Boolean(anchorEl);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const dispatch = useDispatch();
  const buttons = buttonStyles();
  const handleClickHashtag = (event) => {
    setHashtagDialog(true)
  };
  const handleCloseAi = () => {
    setHashtagDialog(false)
  }

  const handleClickAiAssist = () => {
    setOpenAi(true);

  };

  const handleCloseAiAssist = () => {
    setOpenAi(false);
    setAiTextData([])
    setAiData(false)
    setAddButton(false)
  }

  const fetchAitextData = async () => {
    setLoader(true)
    await api.generateTitle({
      "text": hashtags
    }).then((res) => {
      if(res.status==200){
        setAiData(true)
      }
      setLoader(false)
      setAiTextData(Object?.values(res.data?.title?.split('\n')?.filter(Boolean)))
    })
  }
  const handleChangeInputVal = (e) => {
    setHashtags(e.target.value)
  }

  const formattedMessage = aiTextData.map((message) =>
  message.replace(/^\d+\.\s*"?|"?$/g, '').trim())
  
    const [checkedItems, setCheckedItems] = React.useState("")
    const handleCheckboxChange = (item) => (event) => {
        const isChecked = event.target.checked;
          if(event.target.checked===true){
            setAddButton(true)
          }
          else
          {
              setAddButton(false)
          }
        // Update the checked value in the state
        setCheckedItems((prevCheckedItems) => {
            const updatedCheckedItems = { ...prevCheckedItems };
            updatedCheckedItems[item] = isChecked;
            return updatedCheckedItems;
        });

        // Update the post data with the selected values
        setPostData((prevPostData) => {
            const updatedPostData = prevPostData?.map((obj) => {
                let newMessage = obj.message || '';
                if (isChecked) {
                    // Append the selected value to the existing message string
                    newMessage += ' ' + item;
                } else {
                    // Remove the selected value from the existing message string
                    newMessage = newMessage?.replace(item, '').trim();
                }
                return { ...obj, message: newMessage };
            });
            return updatedPostData;
        });
    };

    // Display the selected text
    const selectedText = Object.keys(checkedItems)
        .filter((item) => checkedItems[item])
        .join(' ');
        console.log('selectedText',selectedText);
        
    const handlePostData = () => {
      const updatedArray = socialAccountEditorData.map(obj => {
        let newMessage = obj.message || '';
        // Append the selected value to the existing message string
        newMessage += ' ' + selectedText;
        return { ...obj, message: newMessage };
      });
    
        dispatch(handleTextChange(updatedArray));
        handleCloseAiAssist()
        setAiTextData([])
        setAiData(false)
        setAddButton(false)
    }
  return (
    <Box display="flex" sx={{ ml: 3 }}>

      <Button
      className={buttons.Grey_small_Btn}
        onClick={handleClickHashtag}
      >
        # Hashtags
      </Button>

      <HashTagGeneratorDialog
        open={hashtagDialog}
        onClose={handleCloseAi}
        setOpenAi={setOpenAi} />

      <Button
        sx={{ml:2}}
        className={buttons.Grey_small_Btn}
        onClick={handleClickAiAssist}
        variant="p"
      >
        AI Assist
      </Button>
      <Dialog
        open={openAi}
        onClose={handleCloseAiAssist}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid className='title-grid'>
          <Typography className='AI-title'>
            Ai Generator
          </Typography>
          <IconButton onClick={handleCloseAiAssist} disableRipplex>
            <CloseIcon sx={{ height: '24px', width: "24px", color: '#000' }} />
          </IconButton>
        </Grid>
        <Box>
          <TextareaAutosize
            minRows={6}
            style={{ width: 500 }}
            className="all-textarea-ai"
            placeholder="Write a tagline to be used on."
            onChange={handleChangeInputVal}

          />
        </Box>
        <Box className='aiAssistBtnBox'>
          {Loader ? <CircularProgress /> :
            <Button onClick={fetchAitextData}
              className={buttons.Blue_Color_Outlined_Btn}
              variant="outlined">
              Generate
            </Button>}
        </Box>
       {aiData && <> 
       <Box className='AiAnswerBox'>
          {formattedMessage?.map((item) =>
            <Grid display="flex">
              <Checkbox onClick={handleCheckboxChange(item)} />
              <Typography variant="h5" sx={{ mt: "9px" }}>{item}</Typography>
            </Grid>
          )}
        </Box>
        {addButton ? 
           <Box className='AiAssistAddBtnBox'>
           <Button onClick={handlePostData} variant="contained"
             className={buttons.Blue_color_Btn}>Add to post</Button>
         </Box>
         :
         <Box className='AiAssistAddBtnBox'>
           <Button 
             className={buttons.Blue_color_disabled_Btn}>Add to post</Button>
         </Box>}
         </>}
      </Dialog>

    </Box>
  );
}

export default HashtagandAi;
