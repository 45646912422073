import { createSlice } from '@reduxjs/toolkit';
import { getAllBrandData, getAllSocialAccountData ,getUserData,getBrandSettingData, getCurrentPlanDetailsApiData,} from '../reduxThunk';


const initialState = {
  brandId: '',
  selectedSocialAccountId: '',

  // all brand info
  allBrandLoading: false,
  allBrandData: [],
  allBrandError: '',

  // all social account
  allSocialAccountLoading: false,
  allSocialAccountError: '',
  allSocialAccountData: [],

  //user data
  userDataLoading: false,
  userDataError: '',
  userData: [],

  // Brand:
  brandSettingLoading: false,
  brandSettingData: [],
  brandSettingError: '',

  // current plan data:-
  CurrentPlanDetailsDataLoading: false,
  CurrentPlanDetailsDataError: '',
  CurrentPlanDetailsData: [],

  // account info
  accountSettingsData: {},

  // 
  token: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    handleSelectedBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    handleSelectedSocialAccountId: (state, action) => {
      state.selectedSocialAccountId = action.payload;
    },
    handleAccountSettingsData: (state, action) => {
      state.accountSettingsData = action.payload;
    },
    handleToken: (state, action) => {
      state.token = action.payload;
    },
    handleRestRedux: (state=initialState, action) => {
      state.allBrandData=[];
      state.userData=[];
      state.brandId='';
      state.brandSettingData=[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBrandData.pending, (state, action) => {
      state.allBrandLoading = true;
    });
    builder.addCase(getAllBrandData.fulfilled, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandData = action.payload;
    });
    builder.addCase(getAllBrandData.rejected, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandError = 'error data';
      state.allBrandData = [];
    });

    builder.addCase(getAllSocialAccountData.pending, (state, action) => {
      state.allSocialAccountLoading = true;
    });
    builder.addCase(getAllSocialAccountData.fulfilled, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountData = action.payload.data;
    });
    builder.addCase(getAllSocialAccountData.rejected, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountError = 'error data';
      state.allSocialAccountData = [];
    });
    builder.addCase(getUserData.pending, (state, action) => {
      state.userDataLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.userDataLoading = false;
      state.userDataError = 'error data';
      state.userData = [];
    });
    builder.addCase(getBrandSettingData.pending, (state, action) => {
      state.brandSettingLoading = true;
    });
    builder.addCase(getBrandSettingData.fulfilled, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingData = action.payload;
    });
    builder.addCase(getBrandSettingData.rejected, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingError = 'error data';
      state.brandSettingData = [];
    });
    builder.addCase(getCurrentPlanDetailsApiData.pending, (state, action) => {
      state.CurrentPlanDetailsLoading = true;
    });
    builder.addCase(getCurrentPlanDetailsApiData.fulfilled, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsData = action.payload;
    });
    builder.addCase(getCurrentPlanDetailsApiData.rejected, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsDataError = 'error data';
      state.CurrentPlanDetailsData = [];
    });
    
  }

});

// Action creators are generated for each case reducer function
export const {
  handleSelectedBrandId,
  handleSelectedSocialAccountId,
  handleRestRedux,
  handleAccountSettingsData,
  handleToken
} = userSlice.actions;

export default userSlice.reducer;
