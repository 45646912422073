import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import linkedinlogo from "../assets/images/linkedin.png";
import instagramlogo from "../assets/images/Instagram.png";
import pinterestlogo from "../assets/images/pinterest.png";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import moment from "moment";
import * as api from "../redux/Api";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from "react-redux";
import { getPostsData } from "../redux/reduxThunk";
import {
  LinkedinIcon,
  YoutubeIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  FacebookIcon,
} from '../assets/assets/assets';
import { handleOpenScheduleDialog, handleIsEditScheduleDialog } from "../redux/Slice/scheduleDialog";
import { handleTextChange } from "../redux/Slice/textArea";
import { handleAllUploadImage, handleUploadVideo } from "../redux/Slice/allImagesUpload";
import ScheduleDialog from "../components/ScheduleDialog";
import { useSelector } from "react-redux";
import { buttonStyles } from "../Themes/buttonTheme";
import NestedAvatar from "../components/common/NestedAvatar";
export default function EventCard({ event, setIsEditOpen }) {
  const { allSocialAccountData } = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(new Date(event?.publishDateTime))
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const [currentEvent, setCurrentEvent] = React.useState()
  const { openScheduleDialog } = useSelector((state) => state.openScheduleDialog);
  const anchorRef = React.useRef(null);
  const [isEdit, setIsEdit] = React.useState()
  const dispatch = useDispatch()
  const buttonClass = buttonStyles();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleDeletePost = async (id) => {
    await api.deletePosts(id).then((res) => {
      let post_data;
      const idArray = Object.values(allSocialAccountData)
        .filter(obj => obj && obj._id) // Remove null or undefined objects
        .map(obj => obj._id.toString()); // Extract the _id property and convert it to a string
      post_data = {
        "socialId": idArray,
        "startDate": "",
        "endDate": "",
        "status": "",
        "scheduleType": ""
      }
      dispatch(getPostsData(post_data))
      dispatch(getPostsData())
    })
  }
  const handleClose = async (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleEditPost = (event) => {
    console.log("image_type", event?.postType)
    setCurrentEvent(event);
    if (event?.postType === "image") {
      dispatch(handleAllUploadImage(event.imageUrl));
    }
    if (event?.postType === "video") {
      dispatch(handleUploadVideo(event.imageUrl))
    }
    dispatch(handleTextChange([event]));
    setIsEditOpen(true)
    dispatch(handleIsEditScheduleDialog(true))
    dispatch(handleOpenScheduleDialog(true));
  }
  const getBrandName = (socialAccountName, allSocialAccountData) => {
    let brandName;
    switch (socialAccountName) {
      case "facebookPage":
        brandName = allSocialAccountData?.facebookPage?.pageName;
        break;
      case "facebookGroup":
        brandName = allSocialAccountData?.facebookGroup?.pageName;
        break;
      case "instagram":
        brandName = allSocialAccountData?.instagram?.pageName;
        break;
      case "google":
        brandName = allSocialAccountData?.google?.pageName;
        break;
      case "linkedin":
        brandName = allSocialAccountData?.linkedin?.pageName;
        break;
      default:
        brandName = "";
        break;
    }
    return brandName;
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'orange';
      case 'published':
        return 'green';
      case 'failed':
        return 'red';
      case 'deleted':
        return 'gray';
      default:
        return 'black';
    }
  };


  return (
    <Box sx={{ transform: "translate(-60%, 10px)" }}>
      <Card
        sx={{
          width: "400px",
          borderRadius: '16px',
          border: "solid 1px #e9e9e9",
          backgroundColor: "white",
          zIndex: 2,
          padding: "10px 10px 10px 10px"
        }}
      >
        <Box>
          <Box display="flex" className="preview-box">
            {/* <Avatar
              size="sm"
              src={event?.profileImage}
              sx={{
                marginRight: "10px",
                border: "1px solid",
                width: "40px",
                height: "40px",
                borderColor: "background.body",
              }}
            /> */}
            <NestedAvatar
              // key={index}
              height={50}
              width={50}
              // sx={{ height: "40px", width: "40px" }}
              src1={event?.profileImage}
              src2={event?.socialImage}

            />
            <Grid container xs={12} sx={{ marginTop: '8px', justifyContent: 'space-between' }}>
              <Grid xs={6}>
                <Typography variant="h2" className="card-event-title">{getBrandName(event.socialAccountName, allSocialAccountData)}</Typography>
                <Typography variant="h4" className="card-event-date">{moment(event.publishDateTime).format("YYYY-MM-DD h:mm a")}</Typography>
              </Grid>
              <Box xs={6} sx={{ border: '1px solid #e2e2e2', height: '35px', borderRadius: "8px", p: '7px' }}>

                <Typography
                  variant="published"
                  sx={{
                    display: "flex",
                    
                  }}
                >
                  <Typography
                    sx={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: getStatusColor(event?.status),
                      borderRadius: "50%"
                    }}>

                  </Typography>
                  <Typography sx={{ml:1,color: "lightgreen",
                    marginBottom: "20px",
                    fontFamily: "Rubik",
                    fontSize: "12px",
                    fontWeight: "600",
                    overflowWrap: "break-word"}}>
                    {event.scheduleType}
                  </Typography>

                </Typography>

                {/* <img src={event?.socialImage} className="publishedLogo" alt="" /> */}


              </Box>
            </Grid>
          </Box>
          <Box display="flex">
            {event.postType === "video" ?
              <video
                className="card_image"

                controls
                src={event?.imageUrl[0]}
              ></video> : <img src={event?.imageUrl[0]} className="card_image" alt="" />}

            <Typography variant="content" sx={{
              pr: 3,
              paddingRight: "24px",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.38,
              letterSpacing: "normal",
              color: " #6d6d6d"
            }}>
              {event.message}
            </Typography>
          </Box>
          {/* <Box display="flex" marginLeft="20px">
            <Box display="block" className="card-block">
              <Typography variant="h2" className="card-subtitle">{event.reach}</Typography>
              <Typography variant="h4" className="card-social-media-title">Reach</Typography>
            </Box>
            <Box display="block"  className="card-block">
              <Typography variant="h2" className="card-subtitle">{event.likes}</Typography>
              <Typography variant="h4" className="card-social-media-title">Likes</Typography>
            </Box>
            <Box display="block"  className="card-block">
              <Typography variant="h2" className="card-subtitle">{event.engagement}</Typography>
              <Typography variant="h4" className="card-social-media-title">Engagement</Typography>
            </Box>
            <Box display="block"  className="card-block">
              <Typography variant="h2" className="card-subtitle">{event.comments}</Typography>
              <Typography variant="h4" className="card-social-media-title">Comments</Typography>
            </Box>
          </Box> */}

          <Box display="flex" className="lastUpdate">
            {/* <Typography className="lastupdated">Last updated: <br/> {moment(event.publishDateTime).format("YYYY-MM-DD h:mm a")}</Typography> */}
            {/* <Typography variant="lastupdated">
            
            </Typography> */}
            {/* <Box className="visbleIcons"> */}
            {/* <RemoveRedEyeOutlinedIcon className="removeRedEyeOutlinedIcon" /> */}

            {/* <MenuOutlinedIcon
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              /> */}
            <Grid display="flex" justifyContent='end'>
              <Button className="delete_btn" onClick={() => handleDeletePost(event._id)}>Delete</Button>
              {!(currentDate < yesterday) && <Button className="edit_btn" onClick={() => handleEditPost(event)}>Edit</Button>}

            </Grid>

            {/* <Stack direction="row" spacing={2}>
                <div>

                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          zIndex: 1,
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleClose}>
                                Edit Posts
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                Duplicate Posts
                              </MenuItem>
                              <MenuItem onClick={() => handleDeletePost(event._id)}>
                                Delete Posts
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>

                      </Grow>
                    )}
                  </Popper>
                </div>
              </Stack>
            </Box> */}
          </Box>
        </Box >
      </Card >
      <Box>
        {openScheduleDialog && currentEvent && <ScheduleDialog event={currentEvent} />}
      </Box>
    </Box >

  );
}
