import { GifSharp } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Emojis: [],
  Gifs: '',
  
};

export const InputEditorSlice = createSlice({
  name: "InputEditor",
  initialState,
  reducers: {
    createEmojisState: (state, action) => {
      state.Emojis = action.payload;
    },
    createGifState: (state, action) => {
      state.Gifs = action.payload;
    },
  },

});

// Action creators are generated for each case reducer function
export const { createEmojisState, createGifState, } = InputEditorSlice.actions;

export default InputEditorSlice.reducer;
