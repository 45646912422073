import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedActionIcons: {
    facebookIcon:true,
    instagramIcon:false,
    twitterIcon:false,
    linkedInIcon:false,
    pinteresticon:false,
    googoleBusinessicon:false,
    activeButton:false,
   },
};

export const IconsActionSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    selectedPubishIcons: (state, action) => {
      state.selectedActionIcons = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { selectedPubishIcons } = IconsActionSlice.actions;

export default IconsActionSlice.reducer;
