import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCalender } from "../redux/Slice/calender";
import MonthComponent from "./calender/monthView";
import TopCalender from "./calender/TopCalender";
import WeekComponent from "./calender/WeekView";
import SideBar from '../components/sideBar/index';
import LeftBar from '../components/sideBar/Sidebar'
import { useForm } from "react-hook-form";
import { getAllBrandData, getAllSocialAccountData, getUserData, getBrandSettingData, getCurrentPlanDetailsApiData, getPostsData,getMediaLibraryData} from "../redux/reduxThunk";
import Cookies from 'js-cookie';
import SideBarLibrary from "./SideBarLibrary/sidebarLibrary";
import * as api from "../redux/Api";
import {handleAccountSettingsData} from "../redux/Slice/user";
import { setCookie, getCookie, removeCookie } from '../helpers/cookie';

function Navigation({ title }) {
  const { calender } = useSelector((state) => state);
  const { allSocialAccountData } = useSelector((state) => state.user);
  const { postData } = useSelector((state) => state.post);
  const selected_brand_id = getCookie('created_brand_id');

// console.log("calender",calender)

  const dispatch = useDispatch()
  const dataFetchedRef = React.useRef(false);
  const { userData } = useSelector((state) => state.user);
  let post_data;
  useEffect(() => {
    if (allSocialAccountData.count > 0) {
      const idArray = Object.values(allSocialAccountData)
        .filter(obj => obj && obj._id) // Remove null or undefined objects
        .map(obj => obj._id.toString()); // Extract the _id property and convert it to a string
      post_data = {
        "socialId": idArray,
        "startDate": "",
        "endDate": "",
        "status": "",
        "scheduleType": ""
      }
      dispatch(getPostsData(post_data))
    }
  }, [allSocialAccountData.count])
  const fetchBrandSettingsData = () => {
    const data={"filterByType":"","filterByUsed":"","search":""}
    dispatch(getAllBrandData(userData?.data?.accountType));
    dispatch(getAllSocialAccountData(selected_brand_id));
    dispatch(getUserData());
    dispatch(getBrandSettingData(selected_brand_id));
    dispatch(getCurrentPlanDetailsApiData());
    dispatch(getMediaLibraryData(data))
  };
  const fetchAccountSettingsData = async () => {
      await api.accountSettingsApi().then((res) => {
        console.log("res",res.data)
        dispatch(handleAccountSettingsData(res?.data));
    });
  }
  React.useMemo(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchBrandSettingsData();
    fetchAccountSettingsData()

  }, []);

  return (
    <>
      <SideBar title={title} />
      <Grid
        container-fluid
        columns={12}
        spacing={2}
        display="flex"
        className="account-setting-tab"
      >
        <Grid item md={2} lg={2} xl={2}>
          <LeftBar />
        </Grid>
        <Grid item md={10} lg={10} xl={10} sx={{width:'100%',height:'100vh'}}>
          {title === "Calendar" ?
            <>
              <Box>
                <TopCalender />
              </Box>
              <Box sx={{ borderLeft: '3px solid #F8F8F8'}}>
                {calender.monthView && (
                  <MonthComponent
                    date={new Date(calender.date)}
                    events={postData}
                  />
                )}
                {calender.weekView && (
                  <WeekComponent
                    date={new Date(calender.date)}
                    events={postData}
                  />
                )}
              </Box>
            </>
            : null}
             {title === "Media Library" ?
             <>
              <SideBarLibrary/>
             </>
             :null}
        </Grid>
      </Grid>
    </>
  )
}

export default Navigation