import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Unsplash: [],
};

export const UnsplashSlice = createSlice({
  name: "unsplashPhotos",
  initialState,
  reducers: {
    handleUnsplashPhotos: (state, action) => {
      state.Unsplash = (action.payload);
    },
  },
});
// Action creators are generated for each case reducer function
export const { handleUnsplashPhotos, handleDeleteUnsplashPhotos,  } =
UnsplashSlice.actions;

export default UnsplashSlice.reducer;
