import React, { useRef, useState, useMemo, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import GooglePhotos from "../../assets/images/google-photos.png";
import Googledrive from "../../assets/images/google-drive.png";
import ImageGallery from "../../assets/images/gallery.png";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GoogleDrive from "./createPostTools/GoogleDrive/index";
import {
  Button,
  Dialog,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../components/style.css";
import { useSelector } from "react-redux";
import SearchLocation from "./createPostTools/SearchLocation";
import SocialMediaImageUpload from "./ImageUpload/SocilMediaImageUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import HashtagandAi from "../../pages/HashtagandAi/HashtagandAi";
import UnsplashLogo from "../../assets/images/unsplash.png";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DocumentPreview from "./createPostTools/DocumentPreview";
import documentUpload from "../../redux/Slice/documentUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import { handleTextChange } from "../../redux/Slice/textArea";
import MediaLibraryMoadal from "../../pages/mediaLibrary/MediaLibraryMoadal";
import Cookies from "js-cookie";
import UnsplashModal from "./createPostTools/Unsplah/UnsplashModal";
import { createGifState } from "../../redux/Slice/InputEditorState";
import Menu from "@mui/material/Menu";
import GiphyModal from "./createPostTools/GiphyComponent/GiphyModal";
import {
  handleAllUploadImage,
  handleDeleteAllImage,
  handleDeleteAllVideo,
} from "../../redux/Slice/allImagesUpload";
import * as api from "../../redux/Api";
import VideoUpload from "../common/VideoUpload";
import { setCookie, getCookie, removeCookie } from '../../helpers/cookie';
import CircularProgress from '@mui/material/CircularProgress';
import GifIcon from '@mui/icons-material/Gif';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { getMediaLibraryData } from "../../redux/reduxThunk";
import CloseIcon from '@mui/icons-material/Close';
function EditorComp({ textAreaName, props, setSelectFile, idImage, url }) {

  const { allImagesUpload, ImageLoading } = useSelector((state) => state.allImagesUpload);
  const { allMediaLibraryData } = useSelector((state) => state.libraryImage)
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { allSocialAccountData } = useSelector((state) => state.user);
  const { imageLimit } = useSelector((state) => state.imageUpload);
  const [emoji, setEmoji] = React.useState(false);
  const [emojis, setEmojis] = React.useState(false);
  const [giphys, setGiphys] = React.useState(false);
  const [location, setLocation] = React.useState(false);
  const [drive, setDrive] = React.useState(false);
  const [photos, setPhotos] = React.useState(false);
  const [document, setDocument] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSplash, setOpenSplash] = React.useState(false);
  const [library, setLibrary] = React.useState(false);
  const [maxLength, setMaxLength] = React.useState(2000);
  const [image, setImage] = React.useState(null);
  const { Gifs } = useSelector((state) => state.inputEditor);
  const [videoPreview, setVideoPreview] = useState(null);
  const { uploadVideo_url } = useSelector((state) => state.allImagesUpload);
  const [error, setError] = React.useState("")
  const dispatch = useDispatch();

  const onGiphyOpen = (e) => {
    setGiphOpen(true);
  };
  const onLocationSearch = () => {
    setLocation(true);
  };
  const onDriveOpen = () => {
    setDrive(true);
  };
  const onPhotosOpen = () => {
    setPhotos(true);
  };

  const onDocumentOpen = () => {
    setDocument(true);
  };
  const onCloseEmoji = () => {
    setEmojis(false);
    setGiphys(false);
    setLocation(false);
    setPhotos(false);
    setDrive(false);
    setDocument(false);
    setLibrary(false);
  };

  const handleClose = () => {
    setGiphys(false);
  };
  const handleCloseLocation = () => {
    setLocation(false);
  };

  const handleCloseDocument = () => {
    setDocument(false);
  };

  const openLibrary = () => {
    setLibrary(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleImageSubmit = (event) => {
    event.preventDefault();
    props.onUpload(image);
  };

  const openUnsplash = () => {
    setOpenSplash(true);
  };
  const onCloseUnsplash = () => {
    setOpenSplash(false);
  };

  const onCloseLibrary = () => {
    setLibrary(false);
  };
  const handleCloseGiph = () => {
    setGiphOpen(false);
  };
  const createdBrandId = getCookie("created_brand_id");
  const previousValueRef = useRef("");

  const [text, setText] = useState("");
  useEffect(() => {
    const message_data = socialAccountEditorData[0]?.message;
    setText(message_data);
  }, [textAreaName, socialAccountEditorData]);

  const [showPicker, setShowPicker] = useState(false);
  const [giphOpen, setGiphOpen] = React.useState(false);
  const handleBlur = () => {
    let selectedItemIndex = socialAccountEditorData?.findIndex(
      (item) => item.socialAccountName === textAreaName
    );
    let editorCurrentVal = [];
    if (textAreaName === "facebookPage") {
      if (selectedItemIndex >= 0) {
        const updatedItem = {
          ...socialAccountEditorData[selectedItemIndex],
          message: text,
        };
        editorCurrentVal = [
          ...socialAccountEditorData.slice(0, selectedItemIndex),
          updatedItem,
          ...socialAccountEditorData.slice(selectedItemIndex + 1),
        ];
      } else {
        // console.log("else")
        editorCurrentVal.push(
          ...socialAccountEditorData.filter(
            (i) => i.socialAccountName !== "facebookPage"
          ),
          {
            brandId: createdBrandId,
            socialId: allSocialAccountData?.facebookPage?._id,
            socialAccountName: "facebookPage",
            publishType: "",
            publishDateTime: "",
            postType: "text",
            message: text,
            imageUrl: "",
            location: "123455",
          }
        );
      }
    } else if (textAreaName === "facebookGroup") {
      if (selectedItemIndex >= 0) {
        const updatedItem = {
          ...socialAccountEditorData[selectedItemIndex],
          message: text,
        };
        editorCurrentVal = [
          ...socialAccountEditorData.slice(0, selectedItemIndex),
          updatedItem,
          ...socialAccountEditorData.slice(selectedItemIndex + 1),
        ];
      } else {
        editorCurrentVal.push(
          ...socialAccountEditorData.filter(
            (i) => i.socialAccountName !== "facebookGroup"
          ),
          {
            brandId: createdBrandId,
            socialId: allSocialAccountData?.facebookGroup?._id,
            socialAccountName: "facebookGroup",
            publishType: "",
            publishDateTime: "",
            postType: "text",
            message: text,
            imageUrl: "",
            location: "123455",
          }
        );
      }
    } else if (textAreaName === "instagram") {
      if (selectedItemIndex >= 0) {
        const updatedItem = {
          ...socialAccountEditorData[selectedItemIndex],
          message: text,
        };
        editorCurrentVal = [
          ...socialAccountEditorData.slice(0, selectedItemIndex),
          updatedItem,
          ...socialAccountEditorData.slice(selectedItemIndex + 1),
        ];
      } else {
        editorCurrentVal.push(...socialAccountEditorData.filter(i => i.socialAccountName !== "instagram"), {
          brandId: createdBrandId,
          socialId: allSocialAccountData?.instagram?._id,
          socialAccountName: "instagram",
          publishType: "",
          publishDateTime: "",
          postType: "",
          firstComment: "",
          shopGridLink: "",
          message: text,
          imageUrl: "",
          location: "123455"
        })
      }
    }
    dispatch(handleTextChange(editorCurrentVal));
  };
  const handleChangeText = (e) => {
    setText(e.target.value);

    const newText = e.target.value;
    const newCount = 2200 - newText.length;

    if (newCount < 0) {
      setText(text.slice(0, 2200));
      setMaxLength(0);
    } else {
      setText(newText);
      setMaxLength(newCount);
    }

  };

  const onEmojiClick = (emojiObject, event) => {
    if (emojiObject?.emoji) {
      setText((prevInput) => prevInput + emojiObject.emoji);
    }
    setEmoji(false);
  };
  const handleDeleteGiphy = () => {
    dispatch(createGifState(""));
  };
  const handleCloseEmoji = (e) => {
    setEmoji(false);
  };
  const handleOpenEmoji = (e) => {
    setEmoji(true);
  };
  const handleDeleteAllImages = async (url) => {
    await api.deleteUploadImages(url);
    const selectedImageId = allMediaLibraryData.filter((item) => item.mediaUrl === url)
    if (selectedImageId[0]?.mediaUsedBy === "used") {
      await api.deleteMediaLibrary(selectedImageId[0]?._id).then((res) => {
        if (res.status === 200) {
          const data = { "filterByType": "", "filterByUsed": "", "search": "" }
          dispatch(getMediaLibraryData(data));
        }
      });
    }

    dispatch(handleDeleteAllImage(url));
  };

  const handleDeleteAllVideos = async (url) => {
    dispatch(handleDeleteAllVideo(url));
  };
  // console.log("allImg:::",allImagesUpload)
  return (
    <Box className="form-box">
      <FormGroup className="form">
        <Box className="all-content">
          <textarea
            autoFocus
            name={textAreaName}
            className="textArea"
            value={text}
            onBlur={handleBlur}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
            onChange={handleChangeText}
          />

          <Box>
            <HashtagandAi />
          </Box>
          <Box display="flex" sx={{ ml: 1, mr: 1, zIndex: 1 }}>
            {/* {ImageLoading ? <CircularProgress /> : */}

            <>
              {uploadVideo_url && (
                <Box className="img-container-editor">
                  {" "}
                  <video
                    height="150px"
                    width="145px"
                    style={{ marginLeft: "10px" }}
                    controls
                    src={uploadVideo_url}
                  ></video>{" "}
                  <Box className="overlay">
                    <ClearIcon
                      className="video-cancelIcon"
                      onClick={() => handleDeleteAllVideos(uploadVideo_url)}
                    />

                  </Box>{" "}
                </Box>
              )}
              <ImageList cols={4} gap={1} sx={{ overflow: "hidden" }}>
                {allImagesUpload?.map((image, index) => (
                  <Box className="img-container-editor">
                    <ImageListItem>
                      {index === allImagesUpload.length - 1 && ImageLoading ? (
                        <CircularProgress size={24} className="image-loader" />
                      ) : (
                        <>
                          <Box className='image-container'>
                            <img
                              key={image.index}
                              src={image}
                              alt="images"
                              id="uploadedImages"
                            />
                            <IconButton className="CancelIcon"
                              onClick={() => handleDeleteAllImages(image)}>
                              <CloseIcon
                                sx={{ fontSize: 'medium' }}
                              />
                            </IconButton>
                          </Box>
                        </>
                      )}
                    </ImageListItem>
                  </Box>
                ))}

                <ImageListItem>
                  {allImagesUpload.length > 0 ? (
                    <Box className="add-upload-images" sx={{ mt: 0.5 }}>
                      <SocialMediaImageUpload
                        setVideoPreview={setVideoPreview}
                        type={"used"}
                        linkValue={
                          < >
                          <Box display='flex' justifyContent='center'>
                          <AddCircleOutlineRoundedIcon
                              sx={{ fontSize: "30px" }}
                            />
                          </Box>
                          <Box >
                          <Typography variant="body2" textAlign='center'>
                              Drag and drop  or <br/>
                              <span className="blue"> select a <br/>
                              file to upload</span>
                              
                            </Typography>
                          </Box>
                            
                          </>

                        }
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </ImageListItem>
              </ImageList>
            </>
            {/* } */}
            {Gifs && (
              <Box className="giph-container">
                <img src={Gifs} alt="gif" className="giphyimage" />
                <IconButton
                  className="giph-delete"
                  type="button"
                  onClick={() => handleDeleteGiphy()}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {/* </Box> */}
            <Box>
              {documentUpload && (
                // documentUpload?.map((doc, i)=>(
                <div className="pdf-viewer">
                  <embed
                    src={documentUpload}
                    width="450"
                    height="600"
                    style={{ marginLeft: "60px" }}
                  />
                </div>
                // ))
              )}
            </Box>
          </Box>
          <br />

          <Box className={Gifs || allImagesUpload.length > 10 ? "Opaque" : "normal"}>
            {!uploadVideo_url && (
              <Box
                className="uploadFiles"
                display="flex"
                alignItems="center"
                sx={{ margin: " 8px 23px 8px 23px" }}
              >
                <img
                  src={ImageGallery}
                  alt=""
                  className="face-logo-imagegallery"
                />
                <Typography variant="h6" sx={{ mr: 1 }}>
                  Drag and drop or
                </Typography>
                {allImagesUpload.length > 1}
                <FormControl
                  onSubmit={handleImageSubmit}
                  className="slectFile-outside"
                >
                  {/* <ImgUpload uploadItem={'Select a file'}/> */}
                  <SocialMediaImageUpload type={"used"} linkValue={`select a file`} setError={setError} />
                </FormControl>

                <Grid >
                  <Typography variant="p" color='#484444'>Image size limit is 5 mb</Typography>
                </Grid>
                {imageLimit && <Typography color='red'>You can upload only 10 images at a time</Typography>}

              </Box>
            )}
            
          </Box>
        </Box>
        <Box className="facebook-footer">
              <Box className="all-logos">
                <IconButton
                title="Emoji"
                  className="editor_footer_icons"
                  onClick={Gifs ? { disabled: true } : handleOpenEmoji}
                  disableRipple>
                  <InsertEmoticonIcon
                    sx={{ color: "#000", height: "24px", width: "24px" }} />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={emoji}
                  // sx={{mb:10}}
                  onClose={handleCloseEmoji}
                  className="emoji-picker"
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: -15,
                      ml:4,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        width: 10,
                        height: 10,
                        top:'105%',
                        left:167,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "top", vertical: "top" }}
                  anchorOrigin={{ horizontal: "top", vertical: "top" }}
                >
                  {emoji && (
                    // <Picker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClick} />
                    <EmojiPicker
                      pickerStyle={{ width: "100%" }}
                      onEmojiClick={onEmojiClick}
                    />
                    // <Picker data={data} onEmojiSelect={onEmojiClick} />
                  )}
                </Menu>
                <IconButton onClick={onLocationSearch}
                  disableRipple className="editor_footer_icons"
                  title="Location"
                  >
                  <FmdGoodOutlinedIcon
                    sx={{ color: "#000", height: "24px", width: "24px" }}
                  />
                </IconButton>
                {location && (
                  <Dialog
                    open={onLocationSearch}
                    onClose={handleCloseLocation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <SearchLocation className="location-box" />
                  </Dialog>
                )}
                {/* <DescriptionIcon
                  sx={{ mr: 1 }}
                  className={!uploadVideo_url ? "face-logo" : "disable-icons"}
                  onClick={onDocumentOpen}
                /> */}
                {/* {document && !uploadVideo_url && (
                  <DocumentPreview
                    open={document}
                    onClose={handleCloseDocument}
                  />
                )} */}
                {/* <Divider orientation="vertical" flexItem /> */}
                {/* <Button disabled={Gifs ? true:false}> */}
                {/* <img
                  src={Googledrive}
                  className={!uploadVideo_url ? "face-logo" : "disable-icons"}
                  onClick={Gifs ? { disabled: true } : onDriveOpen}
                  alt=""
                /> */}
                {/* </Button> */}

                {/* {drive && !uploadVideo_url && (
                  <Dialog open={onDriveOpen} onClose={onCloseEmoji}>
                    <GoogleDrive />
                  </Dialog>
                )} */}
                {/* <img
                  src={GooglePhotos}
                  className={!uploadVideo_url ? "face-logo" : "disable-icons"}
                  onClick={Gifs ? { disabled: true } : onPhotosOpen}
                  alt=""
                /> */}
                {photos && !uploadVideo_url && (
                  <Dialog open={onPhotosOpen}>
                    <GooglePhotos />
                  </Dialog>
                )}
                {/* <Tooltip title="Delete"> */}
                  <IconButton
                    disableRipple
                    title="Gifs"
                    className="editor_footer_icons"
                    onClick={Gifs ? { disabled: true } : onGiphyOpen}>
                    <GifIcon
                      sx={{ color: "#000", height: "24px", width: "24px" }}
                      className={!uploadVideo_url ? "" : "disable-icons"}

                      type="button"
                      aria-describedby={id}
                      title='Gifs'
                    />
                  </IconButton>

                {/* </Tooltip> */}
                {giphOpen && !uploadVideo_url && (
                  <GiphyModal open={giphOpen} onClose={handleCloseGiph} />
                )}
                {/* <Divider orientation="vertical" flexItem /> */}
                {/* <Grid  className="editor_footer_icons"> */}
                {/* <IconButton
                  disableRipple
                  className="editor_footer_icons"
                  onClick={Gifs ? { disabled: true } : openUnsplash}> */}

                <IconButton
                 title="Media Library"
                  disableRipple
                  className="editor_footer_icons"
                  onClick={Gifs ? { disabled: true } : openLibrary}>
                  <PhotoLibraryOutlinedIcon
                    className={!uploadVideo_url ? "" : "disable-icons"}
                    onClick={Gifs ? { disabled: true } : openLibrary}
                    sx={{ color: "#000", height: "24px", width: "24px" }}
                  />
                </IconButton>

                {library && !uploadVideo_url && (
                  <MediaLibraryMoadal open={library} onClose={onCloseLibrary} />
                )}

                <Grid className="face-logo_grid"  title="Unsplash" >
                  <img
                    src={UnsplashLogo}
                    height="20px"
                    width="20px"
                    className={!uploadVideo_url ? "face-logo " : "disable-icons"}
                    onClick={Gifs ? { disabled: true } : openUnsplash}
                    alt=""
                  />
                </Grid>

                {/* </IconButton> */}

                {/* </Grid> */}

                {openSplash && !uploadVideo_url && (
                  <UnsplashModal open={openSplash} onClose={onCloseUnsplash} />
                )}
              </Box>
              <Box sx={{mt:"10px"}}>
                <Typography variant="h6">{maxLength}/{2200}</Typography>
                {maxLength === 0 && <div>Maximum characters reached!</div>}
              </Box>
            </Box>
      </FormGroup>
      <Typography sx={{ color: "red", marginLeft: "22px", fontSize: "14px" }}>{error}</Typography>
    </Box>
  );
}

export default EditorComp;
