import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import chatIcon from "../../../assets/images/chat.png";
import ShareIcon from "../../../assets/images/send.png";
import greetKaroLogo from "../../../assets/images/Greetkaro.png";
import printrestlogo from "../../../assets/images/pinterest.png";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useSelector } from "react-redux";
import previewimage from "../../../assets/images/image.png";

export default function PrintrestPreview() {
  const { imageUpload } = useSelector((state) => state.imageUpload);
  const { textAreaValue } = useSelector((state) => state.textAreaval);
  const { allSocialAccountData } = useSelector((state) => state.user);

  return (
    <Box  >
    <Card sx={{   m: 4 }}>
        <Box display="flex" className="preview-box">
          <Box display="flex">
            <Avatar
              size="sm"
              src={greetKaroLogo}
              sx={{
                marginRight: "10px",
                border: "1px solid",
                width: "43px",
                height: "42px",
                borderColor: "background.body",
              }}
            />
            <Typography variant="h6">{allSocialAccountData?.pinterest?.pageName}</Typography>
          </Box>
          <img className="sub-title-image" alt="" src={printrestlogo}></img>
        </Box>
        <Box   align="center">
        {imageUpload.length > 0 ? (
          imageUpload?.map((image, i) => (
            <img
              key={i}
              src={image}
              alt="imges"
              id="uploadedImages"
              width={imageUpload.length === 1 ? "100%" : "30%"}
              height={imageUpload.length === 1 ? "100%" : "40%"}
            />
            ))
            ) : (
              <img src={previewimage} alt="" width="90%" height="100%" />
            )}
        </Box>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites" color="neutral" size="sm">
            <FavoriteBorderOutlinedIcon />
          </IconButton>
          <IconButton>
            <img
              className="sub-title-image-chat"
              src={chatIcon}
              color="neutral"
              size="sm"
              alt=""
            />
          </IconButton>
          <IconButton>
            <img
              className="sub-title-image-sent"
              src={ShareIcon}
              color="neutral"
              size="sm"
              alt=""
            />
          </IconButton>
          <IconButton>
            <BookmarkBorderOutlinedIcon />
          </IconButton>
        </CardActions>
        <CardContent>
          <Typography variant="body2" className="preview-card-text">
            {textAreaValue}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
