import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import documentUpload, { handleUpdateDocument } from "../../../../redux/Slice/documentUpload";
import { Viewer } from 'react-doc-viewer';
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import "./documentPreview.css"


function DocumentPreview({open,onClose}) {
  const [pdfFile, setPdfFile] = React.useState(null);
  const [previewUrl, setPreviewUrl] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(1);
  const hiddenFileInput = React.useRef(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  // const [document, setDocument] = React.useState(false);
  
  const dispatch = useDispatch();
  


  const handleClickInput = (e) => {
    hiddenFileInput.current.click();
  };

  const changeHandler = (event) => {
    const selectedFile = event.target.files[0];
    
    const temp = URL.createObjectURL(selectedFile);
    setPdfFile(temp);
    setPreviewUrl(temp);
    dispatch(handleUpdateDocument(temp));
    // console.log("dp:::",);
  };

  // const changePage = (offset) => {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // };

  

  const handlePrevPage = () => {
    setPageNumber(Math.max(pageNumber - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber(Math.min(pageNumber + 1, numPages));
  };
  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // }; 

  const handleClickUploadDocument = (event) =>{
    const selectedFile = event.target.files;
    setPdfFile(selectedFile);
    setPreviewUrl(URL.createObjectURL(selectedFile));
    dispatch(handleUpdateDocument(selectedFile));
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setFileUrl(url);
  };
  


  return (
    <Dialog open={open}  onClick={onClose}>
    <Box>
      <Box display="flex" justifyContent="space-between">
        <DialogTitle id="alert-dialog-title" variant="h3">
          {"Share a Document"}
        </DialogTitle>
        <ClearOutlinedIcon className="closeButtonAi" onClose={onClose}/>
      </Box>
      
      <Divider />
      <Box>
        <Button
          onClick={handleClickInput}
          variant="outlined"
          sx={{ m: 2, width: "94%", textTransform: "capitalize" }}
        >
          Choose file
          <input
            type="file"
            style={{ fontSize: "0px", width: "0%", display: "none" }}
            // className="input-button"
            ref={hiddenFileInput}
            onChange={changeHandler}
            // onChange={handleFileSelect} 
            // accept=".pdf"
          />
        </Button>
        <Box>
          <Typography variant="h4">Document title:</Typography>
          <TextareaAutosize
            minRows={3}
            style={{ width: 500 }}
            className="all-textarea-ai"
            placeholder="Add a descriptive title to your document."
          />
        </Box>
        <Box sx={{ border: "1px solid black", height: "60px" }}>
          {previewUrl && <Typography>{previewUrl?.name}</Typography>}
          <br />
        </Box>
      <Box>
      {/* <button onClick={handlePrevPage}>Previous Page</button> */}
      {/* <button onClick={handleNextPage}>Next Page</button> */}
      {/* <div>
        Page {pageNumber} of {numPages}
      </div> */}
        {previewUrl && (
          <div >
            <embed
              src={previewUrl}
              width="450"
              height="600"
              style={{ marginLeft: "60px" }}
            />
          </div>
        )}
      </Box>
        
          {/* {fileUrl && (
          <div>
        {fileUrl.endsWith('.pdf') ? (
          <Document file={fileUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          // <Viewer fileUrl={fileUrl} />
          ""
          )}
          {console.log("furl:::",fileUrl)}
      </div>
      )} */}
        <Divider />
        <Box align="right">
          <Button variant="outlined">
            Back
          </Button>
          <Button variant="contained" onClick={handleClickUploadDocument}>Done</Button>
        </Box>
      </Box>
    </Box>
     </Dialog>
  );
}

export default DocumentPreview;
