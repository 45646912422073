import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import greetKaroLogo from "../../../assets/images/Greetkaro.png";
import linkedinlogo from "../../../assets/images/linkedin.png";
import { useSelector } from "react-redux";
import previewimage from "../../../assets/images/image.png";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

export default function LinkedInPreview() {
  const { imageUpload } = useSelector((state) => state.imageUpload);
  const { textAreaValue } = useSelector((state) => state.textAreaval);
  const { allSocialAccountData } = useSelector((state) => state.user);

  return (
    <Box >
      <Card sx={{  m: 4 }}>
        <Box display="flex" className="preview-box">
          <Box display="flex">
            <Avatar
              size="sm"
              src={greetKaroLogo}
              sx={{
                marginRight: "10px",
                border: "1px solid",
                width: "43px",
                height: "42px",
                borderColor: "background.body",
              }}
            />
            <Typography variant="h6">{allSocialAccountData?.linkedin?.pageName}</Typography>
          </Box>
          <img className="sub-title-image" alt="" src={linkedinlogo}></img>
        </Box>
        <CardContent>
          <Typography variant="body2" className="preview-card-text">
            {textAreaValue}
          </Typography>
        </CardContent>
        <Box align="center">
          {imageUpload.length > 0 ? (
            imageUpload?.map((image, i) => (
              <img
                key={i}
                src={image}
                alt="imges"
                id="uploadedImages"
                width={imageUpload.length === 1 ? "100%" : "30%"}
                height={imageUpload.length === 1 ? "100%" : "40%"}
              />
            ))
          ) : (
            <img src={previewimage} alt="" width="90%" height="100%" />
          )}
        </Box>
        <CardActions
          aria-label="add to favorites"
          size="sm"
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Button className="facebook-preview-buttons">
            <ThumbUpOffAltIcon />
            <Typography>Like</Typography>
          </Button>
          <Button className="facebook-preview-buttons">
            <InsertCommentRoundedIcon />
            <Typography>Comment</Typography>
          </Button>
          <Button className="facebook-preview-buttons">
            <RepeatOutlinedIcon />
            <Typography>Repost</Typography>
          </Button>
          <Button className="facebook-preview-buttons">
            <SendOutlinedIcon />
            <Typography>Send</Typography>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
