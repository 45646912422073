import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, Grid, IconButton, Menu, Paper } from "@mui/material";
import { selectCalender } from "../redux/Slice/calender";
import "../components/style.css";
import "./style.css";
import facebooklogo from "../assets/images/Facebook.png";
import { styled } from "@mui/material/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import linkedinlogo from "../assets/images/linkedin.png";
import instagramlogo from "../assets/images/Instagram.png";
import pinterestlogo from "../assets/images/pinterest.png";
import googleMyBusinesslogo from "../assets/images/google-my-business.png";
import twitterlogo from "../assets/images/twitter.png";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Avatar, Badge } from "@mui/material";
import FacebookPage from "./common/iconsBox/FacebookPost/FacebookPage";
import FacebookGroup from "./common/iconsBox/FacebookPost/FacebookGroup";
import InstagramBox from "./common/iconsBox/InstagramPost/index";
import TwitterBox from "./common/iconsBox/TwitterPost/index";
import LinkedInBox from "./common/iconsBox/LinkedinPost/index";
import PinterestBox from "./common/iconsBox/PinterestPost/index";
import GoogleBusinessBox from "./common/iconsBox/GoogleBusinessPost/index";
import FacebookPagePreview from "./common/previewCards/FacebookPagePreview";
import FacebookGroupPreview from "./common/previewCards/FacebookGroupPreview";
import InstagramPreview from "./common/previewCards/InstagramPreview";
import LinkedInPreview from "./common/previewCards/LinkedInPreview";
import PrintrestPreview from "./common/previewCards/PinterestPreview";
import GoogleMyBusinessPreview from "./common/previewCards/GoogleMyBusiness";
import BottomNav from "../components/Bottomnav/index";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import PinterestMenu from "./common/PinterestMenu";
import add from "../assets/add.png";
import * as api from "../redux/Api";
import Cookies from "js-cookie";
import MediaImage from "../assets/birthday.png";
import MediaImage1 from "../assets/superHero.png";
import { handleOpenScheduleDialog, handleIsEditScheduleDialog } from "../redux/Slice/scheduleDialog";
import { handleTextChange } from "../redux/Slice/textArea";
import { handleAllUploadImage, handleUploadVideo } from "../redux/Slice/allImagesUpload";
import { setCookie, getCookie, removeCookie } from '../helpers/cookie';
import { createGifState } from "../redux/Slice/InputEditorState";
import { getMediaLibraryData } from "../redux/reduxThunk";
import { buttonStyles } from '../Themes/buttonTheme';

export default function ScheduleDialog({ event }) {
  const { allSocialAccountData, CurrentPlanDetailsData } = useSelector((state) => state.user);
  const { socialAccountEditorData } = useSelector((state) => state.textAreaval);
  const { isEditScheduleDialog } = useSelector((state) => state.openScheduleDialog);
  const [userplanStatus, setUserPlanStatus] = React.useState(false);
  const { allMediaLibraryData } = useSelector((state) => state.libraryImage);
  const { allImagesUpload } = useSelector((state) => state.allImagesUpload);
  const buttonClass = buttonStyles();
  const limitedImages = allMediaLibraryData.slice(0, 3);
  const [iconsData, setIconsData] = React.useState([
    {
      id: allSocialAccountData?.facebookPage?._id
        ? allSocialAccountData?.facebookPage?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={facebooklogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "Facebook",
      name: "facebookPage",
      form: <FacebookPage socialId={allSocialAccountData?.facebookPage?._id} />,
      card: <FacebookPagePreview />,
    },
    {
      id: allSocialAccountData?.facebookGroup?._id
        ? allSocialAccountData?.facebookGroup?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={facebooklogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "Facebook Group",
      name: 'facebookGroup',
      form: <FacebookGroup socialId={allSocialAccountData?.facebookGroup?._id} />,
      card: <FacebookGroupPreview />,
    },
    {
      id: allSocialAccountData?.instagram?._id
        ? allSocialAccountData?.instagram?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={instagramlogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "Instagram",
      name: "instagram",
      form: <InstagramBox socialId={allSocialAccountData?.instagram?._id} />,
      card: <InstagramPreview />,
    },
    {
      id: allSocialAccountData?.linkedIn?._id
        ? allSocialAccountData?.linkedIn?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={linkedinlogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "LinkedIn",
      name: "LinkedIn",
      form: <LinkedInBox />,
      card: <LinkedInPreview />,
    },
    {
      id: allSocialAccountData?.pinterest?._id
        ? allSocialAccountData?.pinterest?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={pinterestlogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "Pinterest",
      name: "Pinterest",
      form: <PinterestBox />,
      card: <PrintrestPreview />,
      selectBoard: <PinterestMenu />,
    },
    {
      id: allSocialAccountData?.google?._id
        ? allSocialAccountData?.google?._id
        : null,
      icon: (
        <Avatar
          className="sub-title-image"
          src={googleMyBusinesslogo}
          sx={{ width: "32px", height: "32px" }}
        />
      ),
      class: "notactiveButton",
      title: "Google My Business",
      name: "google",
      form: <GoogleBusinessBox />,
      card: <GoogleMyBusinessPreview />,
    },
  ]);

  const dispatch = useDispatch()
  const [formsAndCards, setFormsAndCards] = React.useState();
  useEffect(() => {
    const firstNotNullIdIndex = iconsData.findIndex((item) => item.id !== null);

    const updatedIconsData = iconsData.map((item, index) => {
      if (index === firstNotNullIdIndex) {
        return { ...item, class: "activeButton" };
      } else {
        return { ...item, class: "notactiveButton" };
      }
    });

    setIconsData(updatedIconsData);
  }, [])
  useEffect(() => {
    if (!isEditScheduleDialog) {
      const filterData = iconsData?.filter((item) => item.id !== null)
      setFormsAndCards([filterData[0]])

    } else {
      const filterData = iconsData?.filter((item) => item.id === socialAccountEditorData[0]?.socialId)
      setFormsAndCards([filterData[0]])
    }

  }, [isEditScheduleDialog])



  const { openScheduleDialog } = useSelector((state) => state.openScheduleDialog);

  const calender = useSelector(selectCalender);
  const createdBrandId = getCookie("created_brand_id");
  const selected_brand_id = getCookie("created_brand_id");

  // Functionality for social media forms and cards during creating and preview the posts.
  const showFormsAndCards = (e, id, form, card, textAreaName) => {
    iconsData.forEach((obj) => {
      if (obj?.id === id) {
        obj.class = "activeButton";
      }
    });
    let tempForm = [...formsAndCards, { id, form, card }];
    let uniqueFormObjects = tempForm.reduce((acc, curr) => {
      if (!acc[curr?.id]) {
        acc[curr?.id] = curr;
      }
      return acc;
    }, {});

    tempForm = Object.values(uniqueFormObjects);
    setIconsData((iconsData) => [...iconsData]);
    setFormsAndCards(tempForm);
  };

  // Functionality for social media forms remove.
  function deleteFormsAndCards(e, idToDelete) {
    let i;
    iconsData.forEach((obj) => {
      if (obj?.id === idToDelete) {
        obj.class = "notactiveButton";
      }
    });
    formsAndCards.forEach((obj, index) => {
      if (obj.id === idToDelete) {
        i = index;
        return;
      }
    });
    const updatedMessagesArray = socialAccountEditorData.filter(
      (message) => message.socialId !== idToDelete
    );
    dispatch(handleTextChange(updatedMessagesArray));
    formsAndCards.splice(i, 1); // remove the object at the index
    setFormsAndCards((formsAndCards) => [...formsAndCards]); // update the state of the array
  }

  const handleClose = async () => {
    dispatch(handleIsEditScheduleDialog(false))
    dispatch(handleAllUploadImage([]));
    dispatch(handleTextChange([]));
    dispatch(handleOpenScheduleDialog(false));
    dispatch(handleUploadVideo(""))
    dispatch(createGifState(''))
    const matchedIds = allImagesUpload.reduce((matchedIds, item) => {
      const matchingData = allMediaLibraryData.find((data) => data.mediaUrl === item);
      if (matchingData) {
        matchedIds.push(matchingData._id);
      }
      return matchedIds;
    }, []);

    console.log(matchedIds);

    await api.deleteMediaLibrary(matchedIds).then((res) => {
      if (res.status === 200) {
        const data = { "filterByType": "", "filterByUsed": "", "search": "" }
        dispatch(getMediaLibraryData(data));
      }
    });

  };


  useEffect(() => {
    if (
      CurrentPlanDetailsData?.data?.planDetails?.socialAccountLimit >
      allSocialAccountData?.count
    ) {
      setUserPlanStatus(true);
    }
  }, [allSocialAccountData]);
  const AddSocialAccounts = async () => {
    window.location.href = `${api.onboardUrl}/upgrade-social-brand-accounts`;
  };

  const upgradeSocialAccounts = () => {
    setCookie("billing_tab_value", 4);
    window.location.href = `${api.onboardUrl}/account-settings`;
  };

  return (
    <Box height="100%">
      <Dialog
        className="socilmedia-dialog"
        fullScreen
        open={openScheduleDialog}
        onClose={handleClose}
      >
        <Grid  sx={{ maxHeight: "90px" }} index={0}>
          <Box className="dialog-top">
            <Typography variant="header1" className="publish-page">
              {!isEditScheduleDialog ? "Create Post" : "Edit Post"}
            </Typography>

            <Button
              className="closeButton"
              onClick={handleClose}
            >
              <CloseOutlinedIcon className="closeOutlinedIcon" />
            </Button>
          </Box>
        </Grid>
        <Grid  sx={{
            display: "flex",
            borderTop: "4px solid #F8F8F8",
            width: "100%",
          }} >
          <Grid  sx={{ borderRight: "4px solid #F8F8F8", width: "60%" }}>
            <Box
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "81px",
                justifyContent: "space-between",
                borderBottom: "4px solid #F8F8F8",
              }}
            >
              {!isEditScheduleDialog &&
                <Box
                  className="face-logos"
                >
                  {iconsData?.filter((item) => item.id !== null).map((item, index) => (
                    <>
                    
                      <IconButton
                        onClick={(e) => {
                          if (item.class === "notactiveButton") {
                            showFormsAndCards(e, item?.id, item?.form, item?.card, item?.name);
                          } else {
                            deleteFormsAndCards(e, item?.id);
                          }
                        }}
                        key={index}
                        className={item?.class}
                      >
                        {item.icon}
                      </IconButton>
                    </>

                  ))}
                  {userplanStatus ? (
                    <Button onClick={AddSocialAccounts} 
                    className={buttonClass.Grey_small_Btn} 
                    sx={{ marginLeft: "16px", cursor: "pointer" }}> 
                    <AddIcon /> Add Account
                    </Button>

                  ) : (

                    <Button onClick={AddSocialAccounts} 
                    className={buttonClass.Grey_small_Btn} 
                    sx={{ marginLeft: "6px", cursor: "pointer" }}>
                      Upgrade Account
                      </Button>
                  )}
                </Box>
              }
            </Box>

            </Grid>
            <Grid  sx={{ width: "40%" }}>
            <Box className="preview_channels">
              <Typography variant="header2" ml={2} alignContent="left">
                Preview Channels
              </Typography>
            </Box>
            </Grid>
          </Grid>
        <Grid
          sx={{
            display: "flex",
            mb: "100px",
            width: "100%",
          }}
          index={1}
        >
          <Grid sx={{ borderRight: "4px solid #F8F8F8", width: "60%" }} >
            {formsAndCards?.map((item, index) => (
              <Box sx={{borderBottom:"4px solid #F8F8F8"}} key={index}>{item?.form}</Box>
            ))}
            <Grid className="bottom-nav-buttons">
              {event === null ? <BottomNav event={null} /> : <BottomNav event={event} />}
            </Grid>
          </Grid>

          <Grid xs={6} md={6} lg={4} index={6} sx={{ width: "40%" }}>
            
            <Grid>
              {formsAndCards?.map((item, index) => (
                <Box  sx={{borderBottom:"4px solid #F8F8F8"}} key={index}>{item?.card}</Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}
