import { createSlice } from "@reduxjs/toolkit";
import {getMediaLibraryData} from "../reduxThunk";
const initialState = {
  libraryImage: [],
  
allMediaLibraryDataLoading:false,
allMediaLibraryDataError:"",
allMediaLibraryData:[] 
};

export const libraryImageSlice = createSlice({
  name: "libraryImage",
  initialState,
  reducers: {
    handleLibraryImage: (state, action) => {
      state.libraryImage = (action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMediaLibraryData.pending, (state, action) => {
      state.allMediaLibraryDataLoading = true;
    });
    builder.addCase(getMediaLibraryData.fulfilled, (state, action) => {
      state.allMediaLibraryDataLoading = false;
      state.allMediaLibraryData = action.payload.data;
    });
    builder.addCase(getMediaLibraryData.rejected, (state, action) => {
      state.allMediaLibraryDataLoading = false;
      state.allMediaLibraryDataError = 'error data';
      state.allMediaLibraryData = [];
    });
},
});
// Action creators are generated for each case reducer function
export const { handleLibraryImage,handleDeleteLibraryImage } =
libraryImageSlice.actions;

export default libraryImageSlice.reducer;
