import * as React from "react";
import { Box, Divider, Typography, Grid } from "@mui/material";
import EditorComp from "../../EditorComp";
import facebooklogo from "../../../../assets/images/Facebook.png";
import "../iconsBox.css";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux"

function FacebookGroup({ socialId }) {
  const { allSocialAccountData } = useSelector((state) => state.user);
  return (
    <Grid className="events-box">
      <Box display="flex">
        <img className="sub-title-image-card" alt="" src={facebooklogo} />
        <Typography variant="header2" className="sub-title-card">{allSocialAccountData?.facebookGroup?.pageName}</Typography>
      </Box>
      {/* <Divider /> */}
      <EditorComp textAreaName={"facebookGroup"} socialId={socialId} />
    </Grid>
  );
};

export default FacebookGroup;
